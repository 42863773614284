// Update these if the enums in heracles_backend/src/providers/Corecard/coreCardProvider.ts are updated
export const TransactionHistoryType = [
    { name: 'Settled', value: '1' },
    { name: 'Outstanding', value: '2' },
    { name: 'Expired', value: '3' },
    { name: 'Warehoused', value: '4' },
    { name: 'Unbilled', value: '5' },
]
export const CoreCardTransactionCode = [
    { name: 'Domestic_Sale', value: '834240' },
    { name: 'International_Sale', value: '834241' },
    { name: 'ACH_PAYMENT', value: '834780' },
    { name: 'AUTO_ACH_PAYMENT', value: '834221' },
    { name: 'BALANCE_TRANSFER', value: '834036' },
]

export enum CharCode {
    Backspace = '8',
    Tab = '9',
    Delete = '46',
    NumDelete = '110',
    DotGrater = '190',
    END = '35',
    DOWN_ARROW = '40',
    KEY_0 = '48',
    KEY_9 = '57',
    NUMPAD_0 = '96',
    NUMPAD_9 = '105',
}
export const CoreCardRedeemRewardsDecision = [
    { name: 'Add', value: '0' },
    { name: 'Reduce', value: '1' },
    { name: 'Redeem', value: '2' },
]

export enum TransactionStatus {
    Settled = '1',
    Outstanding = '2',
    Expired = '3',
    Warehoused = '4',
    Unbilled = '5',
}

export enum TransactionType {
    CashOut = 'CashOut',
    CreditCard = 'CreditCard',
}

export enum CoreCardEmbossingManualStatus {
    Active = 2,
    Delinquent = 3,
    Overlimit = 4,
    Fraud = 7,
    Blocked = 10,
    Closed = 11,
}

export const PayTowardsBalanceOptions = [{ name: 'No', value: '0' }]

export const ReimbursementReasons = [
    { name: 'Payment Made in Error', value: 'Payment made in error' },
    { name: 'Customer Satisfaction', value: 'Customer Satisfaction' },
    { name: 'Customer Requested', value: 'Customer Requested' },
    { name: 'Unauthorized Transaction', value: 'Unauthorized Transaction' },
    { name: 'Reimbursement for Fees', value: 'Reimbursement for Fees' },
    { name: 'System Error', value: 'System Error' },
    { name: 'CSR Error', value: 'CSR Error' },
    { name: 'Customer Error', value: 'Customer Error' },
    { name: 'Collector Error', value: 'Collector Error' },
    { name: 'Fraudulent Payment', value: 'Fraudulent Payment' },
    { name: 'Duplicate Payment', value: 'Duplicate Payment' },
    { name: 'Processor Late', value: 'Processor Late' },
    { name: 'Other', value: 'Other' },
]

// This is the mapping within Aven enum
export enum AciAccountSubType {
    PERSONAL_CHECKING = 'PERSONAL_CHECKING',
    PERSONAL_SAVINGS = 'PERSONAL_SAVINGS',
    BUSINESS_CHECKING = 'BUSINESS_CHECKING',
    BUSINESS_SAVINGS = 'BUSINESS_SAVINGS',
}

/**
 * from https://github.com/heraclescorp/heracles/blob/c644d0179eff97ed39d8c3fb3f7547204576634d/aven_backend/src/util/aciConsts.ts#L432
 * This is the mapping between aven enum and aci values
 */
export enum AciFundingAccountSubTypes {
    PERSONAL_CHECKING = 'PERSONAL CHECKING',
    PERSONAL_SAVINGS = 'PERSONAL SAVINGS',
    BUSINESS_CHECKING = 'BUSINESS CHECKING',
    BUSINESS_SAVINGS = 'BUSINESS SAVINGS',
    VISA = 'VISA',
    MC = 'MC',
    DISC = 'DISCOVER',
    AMEX = 'AMERICAN EXPRESS',
    PINLESS_DEBIT = 'PINLESS DEBIT',
    DEBIT_CARD = 'DEBIT CARD',
    ELOCKBOX = 'ELOCKBOX',
}

export const ACI_BANK_ACCOUNT_FUNDING_ACCOUNT_SUB_TYPES = [
    AciFundingAccountSubTypes.PERSONAL_CHECKING,
    AciFundingAccountSubTypes.PERSONAL_SAVINGS,
    AciFundingAccountSubTypes.BUSINESS_CHECKING,
    AciFundingAccountSubTypes.BUSINESS_SAVINGS,
]
export const ACI_DEBIT_CARD_FUNDING_ACCOUNT_SUB_TYPES = [
    AciFundingAccountSubTypes.VISA,
    AciFundingAccountSubTypes.MC,
    AciFundingAccountSubTypes.DISC,
    AciFundingAccountSubTypes.AMEX,
    AciFundingAccountSubTypes.PINLESS_DEBIT,
    AciFundingAccountSubTypes.DEBIT_CARD,
]

export const BankAccountTypes = [
    { name: 'Personal Checking', value: AciAccountSubType.PERSONAL_CHECKING },
    { name: 'Personal Savings', value: AciAccountSubType.PERSONAL_SAVINGS },
    { name: 'Business Checking', value: AciAccountSubType.BUSINESS_CHECKING },
    { name: 'Business Savings', value: AciAccountSubType.BUSINESS_SAVINGS },
]

export const AutopayCategory = [
    { name: 'STATEMENT_BALANCE', value: 'STATEMENT_BALANCE' },
    { name: 'CURRENT_BALANCE', value: 'CURRENT_BALANCE' },
    { name: 'MINIMUM_DUE', value: 'MINIMUM_DUE' },
    { name: 'CUSTOM_AMOUNT', value: 'CUSTOM_AMOUNT' },
]

export enum CannotConvertReason {
    accountDQ = 'accountDQ',
    accountOverlimit = 'accountOverlimit',
    loanDoesNotQualify = 'loanDoesNotQualify',
    accountStatus = 'accountStatus', // catchall for blocked, OFAC suspended etc
    failedToGetConversionDetails = 'failedToGetConversionDetails',
}

// keep in sync with the backend aven_backend/src/manager/avenCreditCardManager/avenCreditCardManager.types.ts
export enum CoreCardPlanType {
    revolving = 'revolving',
    fixedFinanceCharge = 'fixedFinanceCharge',
    simpleInterest = 'simpleInterest',
    recordingCharge = 'recordingCharge',
}

export const UTC_TIMEZONE = 'UTC'
export const NYC_TIMEZONE = 'America/New_York'
export const CORE_DATE_TIME_FORMAT = 'MM/DD/YYYY hh:mm:ss'
export const DISPLAY_DATE_FORMAT = 'MMM D, YYYY'
export const AVEN_DATE_TIME_FORMAT = 'YYYY-MM-DDThh:mm:ss.SSSZ' //2020-12-01T05:00:00.000Z
export const MMM_DD_DATE_FORMAT = 'MMM DD'
export const MONTH_YEAR_FORMAT = 'MMMM YYYY'
export const MONTH_FORMAT = 'MMMM'
export const DAY_DATE_FORMAT = 'ddd, MMM D, YYYY'

export interface BalanceTransferFormData {
    transferAmount: number
    accountNumber: string
    bankName: string
}

export enum ApiRequireSingleUseToken {
    balanceTransferCashOut = '/balanceTransfer/cashOut',
    balanceTransferDraftCashOut = '/balanceTransfer/draftCashOut',
    balanceTransfer = '/balanceTransfer/postBalanceTransfer',
}

export enum PlaidAccountSubType {
    checking = 'checking',
    savings = 'savings',
}

export enum PlaidAccountType {
    personal = 'Personal',
    business = 'Business',
}

export enum ResetError {
    GENERIC,
    EXPIRED_TOKEN,
}

export const MAX_PAYMENT_AMOUNT_ALLOWED_BY_ACI_IN_DOLLARS = 50000
export const MIN_PAYMENT_AMOUNT_ALLOWED_BY_ACI_IN_DOLLARS = 1
export const MIN_CASHOUT_AND_BALANCE_TRANSFER_AMOUNT_IN_DOLLARS = 1
export const MAX_AMOUNT_ALLOWED_BY_ACI_FOR_CASH_OUT_IN_DOLLARS = 50000
export const MAX_TOTAL_DAILY_BT_PLUS_CASH_OUT_IN_DOLLARS = 50000
export const MAX_AMOUNT_ALLOWED_FOR_CASH_OUT_AFTER_PAYMENT = 5000
export const PAYMENT_AMOUNT_BEFORE_BLOCKING_CASH_OUT = 10000
export const MIN_DAYS_BETWEEN_PAYMENT_AND_CASH_OUT = 14
export const MIN_NEED_TO_CONFIRM_PAYMENT_AMOUNT = 3000
export const MIN_STATEMENT_BALANCE_TO_SHOW_AUTOPAY_WARNING = 5000
// the current minimum OTB hold is 3 business days and we add 1 calendar day to that so we never show the customer an expected
// date that is before the actual release date.  so if the payment is made near a holiday weekend, even a small payment can be
// held for up to 7 calendar days, so we only want to show the OTB hold message on the confirmation screen when the hold is
// longer than 7 days to avoid telling every customer the OTB hold is long due to the "large payment amount" on holiday weekends
export const MAX_DAYS_TO_HOLD_PAYMENT_WITHOUT_SHOWING_EXPECTED_OTB_RELEASE = 7

// Bank info constants need to stay in sync with the same constants defined in backend
export const BANK_ROUTING_NUMBER_LENGTH = 9
export const BANK_ACCOUNT_NUMBER_MIN_LENGTH = 4
export const BANK_ACCOUNT_NUMBER_MAX_LENGTH = 17

export interface IAmortizationLoanTerm {
    apr: number
    loanAmount: number
    loanTermInMonths: number
    monthlyPaymentAmountInDollars: number
    fixedFinanceChargesPerMonth: number
    fixedPrincipalPerMonth: number
}

export const CardReplacementReason = {
    DAMAGED: 'DAMAGED',
    LOST: 'LOST',
}
export const MAX_IN_APP_CARD_REPLACEMENT = 3

export enum DisplayableBalanceTransferOrCashOutStatus {
    Pending = 'pending',
    Canceled = 'canceled',
    Reversal = 'reversal',
    Completed = 'completed',
}

export const ERROR_BALANCE_TRANSFER_NOT_CANCELABLE = 'ERROR_BALANCE_TRANSFER_NOT_CANCELABLE'

export enum AmortizationSource {
    CASH_OUT = 'CASH_OUT',
    CREDIT_CARD_BALANCE_TRANSFER = 'CREDIT_CARD_BALANCE_TRANSFER',
}

export const BalanceTransferPaymentOption = {
    revolving: 'revolving',
    teaserRevolving: 'teaserRevolving',
    amortization: 'amortization',
}

// Keep in sync with aven_ios/avenApp/avenApp/viewControllers/AvenBaseViewController.swift
// NOTE: these are based on CNLabelPhoneNumber... values from the Swift Contacts framework
// see https://developer.apple.com/documentation/contacts/cnlabeledvalue
export enum PhoneLabel {
    IPhone = 'iPhone',
    Main = 'main',
    Mobile = 'mobile',
    OtherFax = 'otherFax',
    WorkFax = 'workFax',
    HomeFax = 'homeFax',
    Pager = 'pager',
    Unknown = 'unknown',
}

export const PAYOFF_WIRE_TRANSFER_DETAILS = {
    routing_number: 125108405,
    account_number: 1500040298,
    beneficiary_name: 'Coastal Community Bank',
}

// https://www.twilio.com/docs/api/errors
// 60200 invalid code
// 20404 expired code
// 60202 Max check attempts reached
// 60203 Max send attempts reached.
// 60212 too many concurrent requests
export const TWILIO_ERROR_TO_I18N_NAMES = new Map([
    [60200, 'twilioError.60200'],
    [20404, 'twilioError.20404'],
    [60202, 'twilioError.60202'],
    [60203, 'twilioError.60203'],
    [60212, 'twilioError.60212'],
])

// Keep this in sync with aven_backend
export enum DisputeReasonDesc {
    MERCHANDISE_OR_SERVICES_NOT_RECEIVED = 'Merchandise/services not received',
    CANCELED_RECURRING_TRANSACTION = 'Canceled recurring transaction',
    POOR_QUALITY = 'Poor quality',
    DAMAGED_OR_DEFECTIVE_MERCHANDISE = 'Defective merchandise',
    COUNTERFEIT_MERCHANDISE = 'Counterfeit merchandise',
    MISREPRESENTATION = 'Misrepresentation',
    CREDIT_NOT_PROCESSED = 'No refund issued after return',
    CANCELED_MERCHANDISE_SERVICES = 'No refund issued after canceling order',
    INCORRECT_CURRENCY = 'Incorrect currency',
    FRAUD = 'Fraud',
    OTHER = 'Other',
}

export const disputeReasonDescDisplay: { [T in DisputeReasonDesc]: string } = {
    [DisputeReasonDesc.FRAUD]: 'I did not make this purchase',
    [DisputeReasonDesc.MERCHANDISE_OR_SERVICES_NOT_RECEIVED]: 'Merchandise/service not received',
    [DisputeReasonDesc.CANCELED_RECURRING_TRANSACTION]: 'Canceled recurring transaction',
    [DisputeReasonDesc.POOR_QUALITY]: 'Poor quality',
    [DisputeReasonDesc.DAMAGED_OR_DEFECTIVE_MERCHANDISE]: 'Defective merchandise',
    [DisputeReasonDesc.COUNTERFEIT_MERCHANDISE]: 'Counterfeit merchandise',
    [DisputeReasonDesc.MISREPRESENTATION]: 'Misrepresentation',
    [DisputeReasonDesc.CREDIT_NOT_PROCESSED]: 'No refund issued after return',
    [DisputeReasonDesc.CANCELED_MERCHANDISE_SERVICES]: 'No refund issued after canceling order',
    [DisputeReasonDesc.INCORRECT_CURRENCY]: 'Charged wrong amount',
    [DisputeReasonDesc.OTHER]: 'Other',
}

export const DisputeStatusToStatusIndicatorCssClass = {
    'Dispute Claim Submitted': 'pending',
    'Dispute Initiated': 'pending',
    'Pending Investigation': 'pending',
    Resolved: 'resolved',
    Canceled: 'canceled',
    Rejected: 'rejected',
}

export enum TypeOfPurchaseTransaction {
    SERVICES = 'SERVICES',
    MERCHANDISE = 'MERCHANDISE',
}

export interface IDisputeQuestionnaire {
    isCardInPossession: boolean | null
    selectedTypeOfPurchase: TypeOfPurchaseTransaction | null
    cardLostDate: string | null
    detailsOfPurchase: string | null
    explanation: string | null
    serviceReceivedDate: string | null
    didConsentToInfoSharing: boolean | null
}

// Keep consistent with backend https://github.com/heraclescorp/heracles/blob/c8a72cfc14ebaa09ef37d7276e45358546dcb7e4/aven_backend/src/manager/giactManager.ts#L70
export const GiactVerificationOutcome = {
    // Default fallback
    UPLOAD_DOCUMENTS: 'UPLOAD_DOCUMENTS',
    PASS: 'PASS',
    CANNOT_BE_VERIFIED: 'CANNOT_BE_VERIFIED',
    ROUTING_NUMBER_INVALID: 'ROUTING_NUMBER_INVALID',
}

export enum PaymentErrorSource {
    ACI = 'ACI',
    CoreCard = 'CoreCard',
    UserPaymentMoreThanBalance = 'UserPaymentMoreThanBalance',
    TimingOfPayment = 'TimingOfPayment',
}

export enum CallBookingReason {
    problemWithPayment = 'problemWithPayment',
    getBackOnTrack = 'getBackOnTrack',
    problemAddDebitCard = 'problemAddDebitCard',
}

// Keep in sync with AddDebitCardIFrameEvent in aven_lambda/aciBillPayDebitCardForwarder/src/public/debit-card-details-form.html
export enum AddDebitCardIFrameEvent {
    addDebitCardIframeSuccessEvent = 'add-debit-card-iframe-success-event',
    addDebitCardIframeValidationErrorEvent = 'add-debit-card-iframe-validation-error-event',
    addDebitCardIframeFailureEvent = 'add-debit-card-iframe-failure-event',
}

export const PAY_IT_FORWARD_DEFAULT_TIP_PERCENTAGE = 0.5

export enum DqReasonEnum {
    lossOfIncomeOrJob = 'lossOfIncomeOrJob',
    refinancingOrSellingHome = 'refinancingOrSellingHome',
    expensesIncreased = 'expensesIncreased',
    waitingForBonusOrCash = 'waitingForBonusOrCash',
    appProblems = 'appProblems',
}

export enum AutoSurveyReasonEnum {
    worryAboutWrongAmount = 'worryAboutWrongAmount',
    worryAboutWrongDate = 'worryAboutWrongDate',
    mightNotHaveEnoughMoney = 'mightNotHaveEnoughMoney',
    likeReviewTransactions = 'likeReviewTransactions',
    notUnderstandWhyTurnOn = 'notUnderstandWhyTurnOn',
    dontKnowHow = 'dontKnowHow',
    other = 'other',
}

export enum AutopayEnrolleeFeedbackSurveyReasonEnum {
    likeTheConvenience = 'likeTheConvenience',
    avoidLateFee = 'avoidLateFee',
    keepCreditScore = 'keepCreditScore',
    enjoyAprDiscount = 'enjoyAprDiscount',
    other = 'other',
}

export enum AppProblemEnum {
    autopayNotWorking = 'autopayNotWorking',
    temporarilyInsufficientFunds = 'temporarilyInsufficientFunds',
    other = 'other',
}

// DQ Understand
export const dqReasonOptions = [
    {
        id: 'loss-of-income-or-job',
        label: 'Loss of income or job',
        name: DqReasonEnum.lossOfIncomeOrJob,
        subOptions: [
            {
                id: 'lost-job',
                label: 'I lost my job',
            },
            {
                id: 'spouse-lost-job',
                label: 'Spouse lost their job',
            },
            {
                id: 'reduced-hours-or-pay',
                label: 'Still have job, but reduced hours or pay',
            },
            {
                id: 'income-other',
                label: 'Other',
            },
        ],
    },
    {
        id: 'refinancing-or-selling-home',
        label: 'Refinancing or selling my home',
        name: DqReasonEnum.refinancingOrSellingHome,
        subOptions: [
            {
                id: 'cashout-refi',
                label: 'Cashout refi',
            },
            {
                id: 'selling-home',
                label: 'Selling my home',
            },
        ],
    },
    {
        id: 'expenses-increased',
        label: 'Expenses increased',
        name: DqReasonEnum.expensesIncreased,
        subOptions: [
            {
                id: 'higher-car-expenses',
                label: 'Car & living expenses are higher',
            },
            {
                id: 'medical-expenses',
                label: 'Medical expenses',
            },
            {
                id: 'childrens-expenses',
                label: 'Children’s expenses',
            },
            {
                id: 'large-invemtment',
                label: 'Large investment needed',
            },
            {
                id: 'other-expense',
                label: 'Other',
            },
        ],
    },
    {
        id: 'bonus-or-cash',
        label: 'Waiting for a bonus or cash',
        name: DqReasonEnum.waitingForBonusOrCash,
        subOptions: [],
    },
    {
        id: 'app-problems',
        label: 'Having problems with app',
        name: DqReasonEnum.appProblems,
        subOptions: [
            {
                id: 'autopay-not-working',
                name: AppProblemEnum.autopayNotWorking,
                label: 'My autopay isn’t working',
            },
            {
                id: 'temporarily-insufficient-funds',
                name: AppProblemEnum.temporarilyInsufficientFunds,
                label: 'Temporarily had insufficient funds. Please try again now.',
            },
            {
                id: 'other-app-problem',
                name: AppProblemEnum.other,
                label: 'Other',
            },
        ],
    },
]

// Autopay Survey
export const autopaySurveyOptions = [
    {
        id: 'worried-about-autopay-amount-wrong',
        label: `I'm worried about autopay amount being wrong`,
        name: AutoSurveyReasonEnum.worryAboutWrongAmount,
    },
    {
        id: 'worried-about-autopay-date-wrong',
        label: `I'm worried about autopay date being wrong`,
        name: AutoSurveyReasonEnum.worryAboutWrongDate,
    },
    {
        id: 'might-not-have-enough-money',
        label: `I might not have enough money in the account`,
        name: AutoSurveyReasonEnum.mightNotHaveEnoughMoney,
    },
    {
        id: 'review-transactions',
        label: `I like to review my transactions`,
        name: AutoSurveyReasonEnum.likeReviewTransactions,
    },
    {
        id: 'not-understand-why-turn-on',
        label: 'I don’t understand why I should turn it on',
        name: AutoSurveyReasonEnum.notUnderstandWhyTurnOn,
    },
    {
        id: 'dont-know-how-to-turn-on',
        label: `I don't know how to turn on autopay`,
        name: AutoSurveyReasonEnum.dontKnowHow,
    },
    {
        id: 'other',
        label: `Other`,
        name: AutoSurveyReasonEnum.other,
    },
]

// Autopay Enrollee Feedback Survey
export const autopayEnrolleeFeedbackSurveyOptions = [
    {
        id: 'like-the-convenience',
        label: `I like the convenience of automatic payments`,
        name: AutopayEnrolleeFeedbackSurveyReasonEnum.likeTheConvenience,
    },
    {
        id: 'avoid-late-fee',
        label: `I want to avoid late fees`,
        name: AutopayEnrolleeFeedbackSurveyReasonEnum.avoidLateFee,
    },
    {
        id: 'keep-credit-score',
        label: `I want to make sure my credit score doesn't drop`,
        name: AutopayEnrolleeFeedbackSurveyReasonEnum.keepCreditScore,
    },
    {
        id: 'enjoy-apr-discount',
        label: `I enjoy having my 0.25% APR discount for using autopay`,
        name: AutopayEnrolleeFeedbackSurveyReasonEnum.enjoyAprDiscount,
    },
    {
        id: 'other',
        label: `Other`,
        name: AutopayEnrolleeFeedbackSurveyReasonEnum.other,
    },
]

export const daysToPayOptions = [
    {
        id: 'ten-days',
        label: '10 days',
        value: 10,
    },
    {
        id: 'thirty-days',
        label: '30 days',
        value: 30,
    },
    {
        id: 'more-than-thirty-days',
        label: 'More Than Thirty Days',
        value: 'More Than Thirty Days',
    },
]

export const MAX_DAY_FROM_TODAY_TO_SCHEDULE_PAYMENT = 10

export enum ExcessPaymentAllocationMethod {
    HIGHEST_APR_FIRST = 'HIGHEST_APR_FIRST',
    CREDIT_CARD_BALANCE_FIRST = 'CREDIT_CARD_BALANCE_FIRST',
}

export const HOUR_TO_RUN_SCHEDULED_PAYMENT_SUBSCRIBER_NYC_TIME = 10

// Keep in sync with TwoFactorCodeDeliveryMethod in aven_backend/src/provider/Twilio/twoFactorProvider.ts
export enum TwoFactorCodeDeliveryMethod {
    sms = 'sms',
    call = 'call',
    email = 'email',
}

export enum AdcOverrideCustomerResponse {
    yes = 'yes',
    no = 'no',
    exceededMaxVerificationAttempts = 'exceededMaxVerificationAttempts',
}

export enum TransactionsReviewedAction {
    keepLocked = 'keepLocked',
    unlockCard = 'unlockCard',
    cardUnlocked = 'cardUnlocked',
}
