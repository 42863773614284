import { sendSMS } from '@/utils/smsUtils'
import format from '@/mixins/format'
import { pifManager, PifScreen } from '@/utils/pifManager'
import { useOverviewStore } from '@/store/overviewStore'

export const shouldShowPayItForward = () => {
    const pifData = useOverviewStore().pifData
    return typeof pifData?.tipAmount === 'number' && pifData.tipAmount > 0
}

export default {
    mixins: [format],
    data() {
        return {
            payItForwardShareTrackedLink: null,
            maxAmount: 0,
        }
    },
    computed: {
        prettyPayItForwardShareLink() {
            if (!this.payItForwardShareLink) {
                return null
            }
            return this.payItForwardShareLink
                .replace('https://', '')
                .replace('http://', '')
                .replace(/\?tl.*/, '')
                .replace(/\?cm.*/, '')
        },
        payItForwardShareLink() {
            return this.payItForwardShareTrackedLink
        },
        payItForwardMaxAmount() {
            return this.maxAmount
        },
        smsMessage() {
            return this.$t('pages.payItForward.smsMessage', { maxAmount: this.toFormattedUSDNoCents(this.payItForwardMaxAmount), pifLink: this.payItForwardShareLink }).toString()
        },
        isHomeProduct() {
            return useOverviewStore().isHomeProduct
        },
        rewardType() {
            return useOverviewStore().pifRewardType
        },
    },
    methods: {
        shareViaText: async function () {
            this.$logEvent('event_send_pif_sms')
            await sendSMS(this.smsMessage)
        },

        getPayItForwardData: async function (screenName: PifScreen) {
            if (!this.isHomeProduct) {
                return
            }

            await pifManager.tryPreloadPifData()

            const pifData = useOverviewStore().pifData
            if (pifData) {
                this.payItForwardShareTrackedLink = pifData.linksPerScreen[screenName]
                this.maxAmount = pifData.tipAmount
            }
        },
        shouldShowPayItForward,
    },
}
