<template>
    <div class="d-grid">
        <button
            :class="buttonClasses"
            :type="type"
            v-bind="$attrs"
            v-on="$listeners"
            :disabled="submitting || disabled"
            :data-testid="dataTestid"
        >
            <span
                v-if="submitting"
                class="loading-spinner"
            />
            <span v-else>
                <!-- Allow image to be placed on either side of the text -->
                <span v-if="imageOnRight"><slot /></span>
                <span
                    v-if="imageSrc"
                    class="d-inline-block"
                    :class="{ 'base-button-icon-invert-color': invertIconColor }"
                >
                    <span :class="imageClasses">
                        <img
                            :src="require(`@/assets/${imageSrc}`)"
                            :alt="imageAlt"
                        >
                    </span>
                </span>
                <span v-if="!imageOnRight"><slot /></span>
            </span>
        </button>
    </div>
</template>

<script>
    export default {
        name: 'BaseButton',
        props: {
            type: {
                type: String,
                default: 'submit',
            },
            submitting: {
                type: Boolean,
                default: false,
            },
            imageSrc: {
                type: String,
                default: null,
            },
            imageAlt: {
                type: String,
                default: '',
            },
            imageClasses: {
                type: String,
                default: 'd-block base-button-icon-container',
            },
            imageOnRight: {
                type: Boolean,
                default: false,
            },
            invertIconColor: {
                type: Boolean,
                default: false,
                required: false,
            },
            buttonClasses: {
                type: String,
                default: 'btn btn-primary btn-block',
            },
            disabled: {
                type: Boolean,
                default: false,
            },
            dataTestid: {
                type: String,
                required: false,
            },
        },
        computed: {
            getImageSrc() {
                if (!this.imageSrc) {
                    return null
                }

                return require(`@/assets/${this.imageSrc}`)
            },
        },
    }
</script>

<style scoped>
    .base-button-icon-invert-color {
        /* Will make the black svg to white */
        filter: invert(1);
    }

    .base-button-icon-container {
        width: 100%;
        height: 100%;
    }

    .base-button-icon-container img {
        width: 100%;
        height: 100%;
    }
</style>
