import { appSessionStorage } from '@/utils/storage'
import { resetAllStoresRequiringAuthentication } from '@/store/storeUtils'
import { logger } from '@/utils/logger'
import { useSessionStore } from '@/store/sessionStore'
import { useAuthStore } from '@/store/authStore'

/**
 * Clears session storage and data in Pinia stores that is protected by cardholder
 * authentication, including session & auth tokens themselves. Does not clear data
 * that isn't protected, like device data.
 */
export const clearTokensAndAllDataRequiringAuthentication = () => {
    logger.info(`Clearing tokens and all data requiring authentication from appSessionStorage & Pinia stores`)
    appSessionStorage.clear()
    useSessionStore().$reset()
    useAuthStore().$reset()
    resetAllStoresRequiringAuthentication()
}
