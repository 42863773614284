import VueRouter, { Route, RouteConfig } from 'vue-router'
import { appSessionStorage, localStorageKey, saveDebugLog } from '@/utils/storage'
import Login from '@/views/Login.vue'
import Card from '@/views/Card.vue'
import Activity from '@/views/Activity.vue'
import Statements from '@/views/Statements.vue'
import { logger } from '@/utils/logger'
import { NATIVE_MESSAGES, sendMessageToNativeApp } from '@/utils/sharedLogic'
import { useSessionStore } from '@/store/sessionStore'
import { useOverviewStore } from '@/store/overviewStore'
import { useDeviceInfoStore } from '@/store/deviceInfoStore'
import { useGlobalUiStore } from '@/store/globalUiStore'
import { DISALLOW_DEEP_LINK_PATHS, RouteNames, RoutePaths } from './router.types'
import { useClickTrackingStore } from '@/store/clickTrackingStore'
import { navigationTabRoutePaths } from '@/utils/navigationTabs'
import { useAuthStore } from '@/store/authStore'

// All crypto routes should be added to this Array
export const CryptoRoutePaths = [RoutePaths.DEPOSIT_CRYPTO]

const routes: Array<RouteConfig> = [
    {
        path: RoutePaths.ROOT,
        redirect: { name: RouteNames.LOGIN },
        meta: {
            public: true,
        },
    },
    {
        path: RoutePaths.LOGIN,
        name: RouteNames.LOGIN,
        component: Login,
        meta: {
            public: true,
            layout: 'auth',
        },
    },
    {
        path: RoutePaths.CARD,
        name: RouteNames.CARD,
        component: Card,
        meta: {
            layout: 'dashboard',
            navigation: RoutePaths.CARD,
        },
    },
    {
        path: RoutePaths.STATEMENTS,
        name: RouteNames.STATEMENTS,
        component: Statements,
        meta: {
            layout: 'dashboard',
            navigation: RoutePaths.STATEMENTS,
        },
    },
    {
        path: RoutePaths.ACTIVITY,
        name: RouteNames.ACTIVITY,
        component: Activity,
        meta: {
            layout: 'dashboard',
            navigation: RoutePaths.ACTIVITY,
        },
    },
    {
        path: RoutePaths.REWARDS,
        name: RouteNames.REWARDS,
        component: () => import('@/views/Rewards.vue'),
        meta: {
            layout: 'dashboard',
            navigation: RoutePaths.REWARDS,
        },
    },
    {
        path: RoutePaths.SET_PASSWORD,
        name: RouteNames.SET_PASSWORD,
        component: () => import('@/views/SetPassword.vue'),
        meta: {
            layout: 'auth',
            navigation: RoutePaths.SET_PASSWORD,
            allowedWithSessionJwt: true,
        },
    },
    {
        path: RoutePaths.PASSWORD_LOGIN,
        name: RouteNames.PASSWORD_LOGIN,
        component: () => import('@/views/PasswordLogin.vue'),
        props: true,
        meta: {
            layout: 'auth',
            navigation: RoutePaths.PASSWORD_LOGIN,
            allowedWithSessionJwt: true,
        },
    },
    {
        path: RoutePaths.CHANGE_PRODUCT,
        name: RouteNames.CHANGE_PRODUCT,
        component: () => import('@/views/ChangeProduct.vue'),
        props: true,
        meta: {
            layout: 'auth',
            navigation: RoutePaths.CHANGE_PRODUCT,
        },
    },
    {
        path: RoutePaths.CARD_ARRIVAL_CHECK,
        name: RouteNames.CARD_ARRIVAL_CHECK,
        component: () => import('@/views/CardArrivalCheck.vue'),
        meta: {
            layout: 'auth',
            navigation: RoutePaths.CARD_ARRIVAL_CHECK,
        },
    },
    {
        path: RoutePaths.SECURE_WITH_LOCATION,
        name: RouteNames.SECURE_WITH_LOCATION,
        component: () => import('@/views/SecureWithLocation.vue'),
        meta: {
            layout: 'auth',
            navigation: RoutePaths.SECURE_WITH_LOCATION,
            allowedWithSessionJwt: true,
        },
    },
    {
        path: RoutePaths.FAST_FUNDING,
        name: RouteNames.FAST_FUNDING,
        component: () => import('@/views/FastFunding.vue'),
        props: true,
        meta: {
            layout: 'auth',
            navigation: RoutePaths.FAST_FUNDING,
        },
    },
    {
        path: RoutePaths.ACTIVATION_REQUIRES_VIDEO_AND_LOCATION,
        name: RouteNames.ACTIVATION_REQUIRES_VIDEO_AND_LOCATION,
        component: () => import('@/views/ActivationRequiresVideoAndLocation.vue'),
        props: true,
        meta: {
            layout: 'auth',
            navigation: RoutePaths.ACTIVATION_REQUIRES_VIDEO_AND_LOCATION,
        },
    },
    {
        path: RoutePaths.CARD_ACTIVATION,
        name: RouteNames.CARD_ACTIVATION,
        component: () => import('@/views/CardActivation.vue'),
        meta: {
            layout: 'auth',
            navigation: RoutePaths.CARD_ACTIVATION,
        },
    },
    {
        path: RoutePaths.CARD_ACTIVATION_CONTINGENCY,
        name: RouteNames.CARD_ACTIVATION_CONTINGENCY,
        component: () => import('@/views/CardActivationContingency.vue'),
        meta: {
            layout: 'auth',
            navigation: RoutePaths.CARD_ACTIVATION_CONTINGENCY,
        },
        props: true,
    },
    {
        path: RoutePaths.CARD_ACTIVATION_CONTINGENCY_MORE_DETAILS,
        name: RouteNames.CARD_ACTIVATION_CONTINGENCY_MORE_DETAILS,
        component: () => import('@/views/CardActivationContingencyMoreDetails.vue'),
        meta: {
            layout: 'dashboard',
            navigation: RoutePaths.CARD_ACTIVATION_CONTINGENCY_MORE_DETAILS,
        },
        props: true,
    },
    {
        path: RoutePaths.CASH_OUT_UPFRONT_V2_ACTIVATION,
        name: RouteNames.CASH_OUT_UPFRONT_V2_ACTIVATION,
        component: () => import('@/views/CashOutUpfrontV2Activation.vue'),
        meta: {
            layout: 'auth',
            navigation: RoutePaths.CASH_OUT_UPFRONT_V2_ACTIVATION,
        },
    },
    {
        path: RoutePaths.MAILER_CODE_ACTIVATION,
        name: RouteNames.MAILER_CODE_ACTIVATION,
        component: () => import('@/views/MailerCodeActivation.vue'),
        meta: {
            layout: 'auth',
            navigation: RoutePaths.MAILER_CODE_ACTIVATION,
        },
    },
    {
        path: RoutePaths.FIXED_TERM_CASH_OUT,
        name: RouteNames.FIXED_TERM_CASH_OUT,
        component: () => import('@/views/FixedTermCashOut.vue'),
        meta: {
            layout: 'auth',
            navigation: RoutePaths.FIXED_TERM_CASH_OUT,
        },
    },
    {
        path: RoutePaths.BALANCE_TRANSFER_DURING_ONBOARDING,
        name: RouteNames.BALANCE_TRANSFER_DURING_ONBOARDING,
        component: () => import('@/views/BalanceTransferDuringOnboarding.vue'),
        meta: {
            layout: 'auth',
        },
    },
    {
        path: RoutePaths.PRIME_V3_POST_ACTIVATION,
        name: RouteNames.PRIME_V3_POST_ACTIVATION,
        component: () => import('@/views/PrimeV3PostActivationPrompt.vue'),
        meta: {
            layout: 'auth',
        },
    },
    {
        path: RoutePaths.AUTHORIZED_CARDHOLDERS,
        name: RouteNames.AUTHORIZED_CARDHOLDERS,
        component: () => import('@/views/AuthorizedCardholders.vue'),
        meta: {
            layout: 'dashboard',
        },
    },
    {
        path: RoutePaths.PUSH_NOTIFICATION_PERMISSION,
        name: RouteNames.PUSH_NOTIFICATION_PERMISSION,
        component: () => import('@/views/PushNotificationPermission.vue'),
        meta: {
            layout: 'auth',
        },
        props: true,
    },
    {
        path: '/paymentSourceForm',
        name: 'PaymentSourceForm',
        component: () => import('@/views/PaymentSourceForm.vue'),
        meta: {
            layout: 'dashboard',
        },
    },
    {
        path: RoutePaths.ADD_DEBIT_CARD,
        name: RouteNames.ADD_DEBIT_CARD,
        component: () => import('@/views/AddDebitCard.vue'),
        props: true,
        meta: {
            layout: 'dashboard',
        },
    },
    {
        path: '/paymentSourceDetail',
        name: 'PaymentSourceDetail',
        component: () => import('@/views/PaymentSourceDetail.vue'),
        meta: {
            layout: 'dashboard',
        },
    },
    {
        path: RoutePaths.MAKE_PAYMENT,
        name: RouteNames.MAKE_PAYMENT,
        component: () => import('@/views/MakePayment.vue'),
        props: true,
        meta: {
            layout: 'dashboard',
        },
    },
    {
        path: RoutePaths.PAYMENT_SUCCESS_CONFIRMATION,
        name: RouteNames.PAYMENT_SUCCESS_CONFIRMATION,
        component: () => import('@/views/PaymentSuccessConfirmation.vue'),
        props: true,
        meta: {
            layout: 'dashboard',
        },
    },
    {
        path: RoutePaths.AUTO_PAY_SUCCESS_CONFIRMATION,
        name: RouteNames.AUTO_PAY_SUCCESS_CONFIRMATION,
        component: () => import('@/views/AutoPaySuccessConfirmation.vue'),
        props: true,
        meta: {
            layout: 'dashboard',
        },
    },
    {
        path: RoutePaths.PAY,
        name: RouteNames.PAY,
        component: () => import('@/views/MakePayment.vue'),
        meta: {
            layout: 'dashboard',
        },
    },
    {
        path: RoutePaths.AUTO_PAY,
        name: RouteNames.AUTO_PAY,
        component: () => import('@/views/MakePayment.vue'),
        props: { configureAutoPay: true },
        meta: {
            layout: 'dashboard',
        },
    },
    {
        path: RoutePaths.SETUP_AUTOPAY,
        name: RouteNames.SETUP_AUTOPAY,
        component: () => import('@/views/SetupAutoPay.vue'),
        meta: {
            layout: 'dashboard',
        },
    },
    {
        path: RoutePaths.ENROLL_IN_AUTOPAY,
        name: RouteNames.ENROLL_IN_AUTOPAY,
        component: () => import('@/views/MakePayment.vue'),
        props: { configureAutoPay: true, isModal: true },
        meta: {
            layout: 'dashboard',
        },
    },
    {
        path: RoutePaths.WIRE_TRANSFER_INSTRUCTIONS,
        name: RouteNames.WIRE_TRANSFER_INSTRUCTIONS,
        component: () => import('@/views/WireTransferInstructions.vue'),
        meta: {
            layout: 'dashboard',
        },
        props: true,
    },
    {
        path: RoutePaths.DEPOSIT_CRYPTO,
        name: RouteNames.DEPOSIT_CRYPTO,
        component: () => import('@/views/DepositCrypto.vue'),
        meta: {
            layout: 'dashboard',
        },
    },
    {
        path: RoutePaths.REPORT_LOST_OR_STOLEN,
        name: RouteNames.REPORT_LOST_OR_STOLEN,
        component: () => import('@/views/ReportLostOrStolen.vue'),
        meta: {
            layout: 'dashboard',
        },
    },
    {
        path: RoutePaths.REPLACE_CARD,
        name: RouteNames.REPLACE_CARD,
        component: () => import('@/views/ReplaceCard.vue'),
        meta: {
            layout: 'dashboard',
        },
    },
    {
        path: RoutePaths.NOTIFICATION_SETTING,
        name: RouteNames.NOTIFICATION_SETTING,
        component: () => import('@/views/NotificationSetting.vue'),
        meta: {
            layout: 'dashboard',
        },
    },
    {
        path: RoutePaths.DOCUMENTS,
        name: RouteNames.DOCUMENTS,
        component: () => import('@/views/Documents.vue'),
        meta: {
            layout: 'dashboard',
        },
    },
    {
        path: '/avenPrimeSetting',
        name: 'AvenPrimeSettings',
        component: () => import('@/views/AvenPrimeSettings.vue'),
        meta: {
            layout: 'dashboard',
        },
    },
    {
        path: '/accountDetails',
        name: 'AccountDetails',
        component: () => import('@/views/AccountDetails.vue'),
        meta: {
            layout: 'dashboard',
        },
    },
    {
        path: RoutePaths.PERSONAL_DETAILS,
        name: RouteNames.PERSONAL_DETAILS,
        component: () => import('@/views/PersonalDetails.vue'),
        meta: {
            layout: 'dashboard',
        },
    },
    {
        path: '/paymentSource',
        name: 'PaymentSource',
        component: () => import('@/views/PaymentSource.vue'),
        meta: {
            layout: 'dashboard',
        },
    },
    {
        path: '/cardHolderDetails',
        name: 'CardHolderDetails',
        component: () => import('@/views/CardHolderDetails.vue'),
        meta: {
            layout: 'dashboard',
        },
    },
    {
        path: '/statement/:id',
        name: 'StatementId',
        component: () => import('@/views/StatementId.vue'),
        props: true,
        meta: {
            layout: 'dashboard',
        },
    },
    {
        path: RoutePaths.SPLASH,
        name: RouteNames.SPLASH,
        component: () => import('@/views/Splash.vue'),
        meta: {
            public: true,
            layout: 'auth',
        },
    },
    {
        path: '/error',
        name: 'Error',
        component: () => import('@/views/Error.vue'),
        meta: {
            public: true,
            layout: 'auth',
        },
    },
    {
        path: '/appBlocked',
        name: 'AppBlocked',
        component: () => import('@/views/AppBlocked.vue'),
        meta: {
            public: true,
            layout: 'auth',
        },
    },
    {
        path: '/unfreezeCard',
        name: 'UnfreezeCard',
        component: () => import('@/views/UnfreezeCard.vue'),
        props: true,
        meta: {
            layout: 'dashboard',
        },
    },
    {
        path: '/balanceTransfer/promo/:promoName',
        name: 'BalanceTransferPromo',
        component: () => import('@/views/BalanceTransferPromo.vue'),
        meta: {
            layout: 'dashboard',
        },
    },
    {
        path: RoutePaths.BALANCE_TRANSFER,
        name: RouteNames.BALANCE_TRANSFER,
        redirect: { name: RouteNames.BALANCE_TRANSFER_REQUEST },
        meta: {
            layout: 'dashboard',
        },
    },
    {
        path: RoutePaths.BALANCE_TRANSFER_PAYMENT_OPTIONS,
        name: RouteNames.BALANCE_TRANSFER_PAYMENT_OPTIONS,
        component: () => import('@/views/BalanceTransferPaymentOptions.vue'),
        meta: {
            layout: 'dashboard',
        },
    },
    {
        path: RoutePaths.BALANCE_TRANSFER_REQUEST,
        name: RouteNames.BALANCE_TRANSFER_REQUEST,
        component: () => import('@/views/BalanceTransferRequest.vue'),
        meta: {
            layout: 'dashboard',
        },
    },
    {
        path: RoutePaths.RECURRING_BALANCE_TRANSFER_SETTINGS,
        name: RouteNames.RECURRING_BALANCE_TRANSFER_SETTINGS,
        component: () => import('@/views/RecurringBalanceTransferSettings.vue'),
        meta: {
            layout: 'dashboard',
        },
        props: true,
    },
    {
        path: RoutePaths.EDIT_RECURRING_BALANCE_TRANSFER_AMOUNT,
        name: RouteNames.EDIT_RECURRING_BALANCE_TRANSFER_AMOUNT,
        component: () => import('@/views/EditRecurringBalanceTransferAmount.vue'),
        meta: {
            layout: 'dashboard',
        },
        props: true,
    },
    {
        path: RoutePaths.BALANCE_TRANSFER_DETAILS_CONFIRMATION,
        name: RouteNames.BALANCE_TRANSFER_DETAILS_CONFIRMATION,
        component: () => import('@/views/BalanceTransferDetailsConfirmation.vue'),
        meta: {
            layout: 'dashboard',
        },
        props: true,
    },
    {
        path: RoutePaths.BALANCE_TRANSFER_COMPLETE,
        name: RouteNames.BALANCE_TRANSFER_COMPLETE,
        component: () => import('@/views/BalanceTransferComplete.vue'),
        meta: {
            layout: 'dashboard',
        },
        props: true,
    },
    {
        path: RoutePaths.BALANCE_TRANSFER_FMP_SWEEP_PROMO,
        name: RouteNames.BALANCE_TRANSFER_FMP_SWEEP_PROMO,
        component: () => import('@/views/BalanceTransferFmpSweepPromo.vue'),
        meta: {
            layout: 'dashboard',
        },
        props: true,
    },
    {
        path: RoutePaths.RECURRING_BT_SELECT_CARDS,
        name: RouteNames.RECURRING_BT_SELECT_CARDS,
        component: () => import('@/views/recurringBalanceTransfer/SelectRecurringBalanceTransferCards.vue'),
        meta: {
            layout: 'dashboard',
        },
    },
    {
        path: RoutePaths.RECURRING_BT_TRANSFER_AMOUNT,
        name: RouteNames.RECURRING_BT_TRANSFER_AMOUNT,
        component: () => import('@/views/recurringBalanceTransfer/SelectRecurringBalanceTransferAmount.vue'),
        // We expect a 'token' query param
        props: true,
        meta: {
            layout: 'dashboard',
        },
    },
    {
        path: RoutePaths.RECURRING_BT_REQUESTED,
        name: RouteNames.RECURRING_BT_REQUESTED,
        component: () => import('@/views/recurringBalanceTransfer/RecurringBalanceTransferRequested.vue'),
        meta: {
            layout: 'dashboard',
        },
    },
    {
        path: RoutePaths.RECURRING_BT_INFORMATION,
        name: RouteNames.RECURRING_BT_INFORMATION,
        component: () => import('@/views/recurringBalanceTransfer/RecurringBalanceTransferInformation.vue'),
        meta: {
            layout: 'dashboard',
        },
    },
    {
        path: RoutePaths.LOGOUT,
        name: RouteNames.LOGOUT,
        component: () => import('@/views/Logout.vue'),
        meta: {
            layout: 'dashboard',
        },
    },
    {
        path: RoutePaths.CASH_OUT,
        name: RouteNames.CASH_OUT,
        redirect: { name: RouteNames.CASH_OUT_HISTORY },
        meta: {
            layout: 'dashboard',
        },
    },
    {
        path: RoutePaths.CASH_OUT_BY_ID,
        name: RouteNames.CASH_OUT_BY_ID,
        component: () => import('@/views/CashOutById.vue'),
        meta: {
            layout: 'dashboard',
        },
    },
    {
        path: RoutePaths.SECURITY_CONFIRMATION,
        name: RouteNames.SECURITY_CONFIRMATION,
        component: () => import('@/views/SecurityConfirmation.vue'),
        meta: {
            layout: 'dashboard',
        },
        props: true,
    },
    {
        path: RoutePaths.SINGLE_SCHEDULED_PAYMENT,
        name: RouteNames.SINGLE_SCHEDULED_PAYMENT,
        component: () => import('@/views/SingleScheduledPayment.vue'),
        props: true,
        meta: {
            layout: 'dashboard',
        },
    },
    {
        path: RoutePaths.SINGLE_USE_TOKEN_VERIFICATION,
        name: RouteNames.SINGLE_USE_TOKEN_VERIFICATION,
        component: () => import('@/views/SingleUseTokenVerification.vue'),
        meta: {
            layout: 'dashboard',
        },
    },
    {
        path: RoutePaths.CASH_OUT_FULL_DRAW,
        name: RouteNames.CASH_OUT_FULL_DRAW,
        component: () => import('@/views/CashOutFullDraw.vue'),
        meta: {
            layout: 'dashboard',
        },
    },
    {
        path: RoutePaths.CASH_OUT_UP_FRONT_BT_INSTEAD,
        name: RouteNames.CASH_OUT_UP_FRONT_BT_INSTEAD,
        component: () => import('@/views/CashOutUpFrontBalanceTransferInstead.vue'),
        meta: {
            layout: 'dashboard',
        },
    },
    {
        path: RoutePaths.CASH_OUT_REQUEST,
        name: RouteNames.CASH_OUT_REQUEST,
        component: () => import('@/views/CashOutRequest.vue'),
        meta: {
            layout: 'dashboard',
        },
    },
    {
        path: RoutePaths.CASH_OUT_PAYMENT_OPTIONS,
        name: RouteNames.CASH_OUT_PAYMENT_OPTIONS,
        component: () => import('@/views/CashOutPaymentOptions.vue'),
        meta: {
            layout: 'dashboard',
        },
    },
    {
        path: RoutePaths.CASH_OUT_PAYMENT_OPTIONS_FULL_DRAW,
        name: RouteNames.CASH_OUT_PAYMENT_OPTIONS_FULL_DRAW,
        component: () => import('@/views/CashOutPaymentOptions.vue'),
        props: { useFullDraw: true },
        meta: {
            layout: 'dashboard',
        },
    },
    {
        path: RoutePaths.CASH_OUT_METHOD,
        name: RouteNames.CASH_OUT_METHOD,
        component: () => import('@/views/CashOutMethod.vue'),
        props: true,
        meta: {
            layout: 'dashboard',
        },
    },
    {
        path: RoutePaths.CASH_OUT_BANK_STATEMENT,
        name: RouteNames.CASH_OUT_BANK_STATEMENT,
        component: () => import('@/views/CashOutBankStatement.vue'),
        meta: {
            layout: 'dashboard',
        },
    },
    {
        path: RoutePaths.CASH_OUT_DETAILS_CONFIRMATION,
        name: RouteNames.CASH_OUT_DETAILS_CONFIRMATION,
        component: () => import('@/views/CashOutDetailsConfirmation.vue'),
        meta: {
            layout: 'dashboard',
        },
    },
    {
        path: RoutePaths.CASH_OUT_SUCCESS_CONFIRMATION,
        name: RouteNames.CASH_OUT_SUCCESS_CONFIRMATION,
        component: () => import('@/views/CashOutSuccessConfirmation.vue'),
        props: true,
        meta: {
            layout: 'dashboard',
        },
    },
    {
        path: RoutePaths.CASH_OUT_HISTORY,
        name: RouteNames.CASH_OUT_HISTORY,
        component: () => import('@/views/CashOutHistory.vue'),
        meta: {
            layout: 'dashboard',
        },
    },
    {
        path: RoutePaths.CREDIT_SCORE_MONITORING,
        name: RouteNames.CREDIT_SCORE_MONITORING,
        component: () => import('@/views/credit/CreditInfoCard.vue'),
        meta: {
            layout: 'dashboard',
        },
    },
    {
        path: RoutePaths.BALANCE_TRANSFER_BY_ID,
        name: RouteNames.BALANCE_TRANSFER_BY_ID,
        component: () => import('@/views/BalanceTransferById.vue'),
        meta: {
            layout: 'dashboard',
        },
    },
    {
        path: RoutePaths.TRANSACTION_BY_ID,
        name: RouteNames.TRANSACTION_BY_ID,
        component: () => import('@/views/TransactionById.vue'),
        props: true,
        meta: {
            layout: 'dashboard',
        },
    },
    {
        path: RoutePaths.PAY_IT_FORWARD,
        name: RouteNames.PAY_IT_FORWARD,
        component: () => import('@/views/PayItForward.vue'),
        props: true,
        meta: {
            layout: 'dashboard',
        },
    },
    {
        path: RoutePaths.BECOME_AMBASSADOR,
        name: RouteNames.BECOME_AMBASSADOR,
        component: () => import('@/views/BecomeAmbassador.vue'),
        props: true,
        meta: {
            layout: 'dashboard',
        },
    },
    {
        path: RoutePaths.PAY_IT_FORWARD_CONTACT_LIST,
        name: RouteNames.PAY_IT_FORWARD_CONTACT_LIST,
        component: () => import('@/views/PayItForwardContactList.vue'),
        props: (route) => ({ waitForPermissions: route.query.wp === 'true' }),
        meta: {
            layout: 'dashboard',
            navigation: RoutePaths.PAY_IT_FORWARD_CONTACT_LIST,
        },
    },
    {
        path: RoutePaths.PAY_IT_FORWARD_CONTACT_LIST_REMIND,
        name: RouteNames.PAY_IT_FORWARD_CONTACT_LIST_REMIND,
        component: () => import('@/views/PayItForwardContactList.vue'),
        props: (route) => ({ waitForPermissions: route.query.wp === 'true', base64PhoneNumberToRemind: route.params.base64PhoneNumberToRemind }),
        meta: {
            layout: 'dashboard',
            navigation: RoutePaths.PAY_IT_FORWARD_CONTACT_LIST,
        },
    },
    {
        path: RoutePaths.PIF_SIGN_ON_BONUS_REAL_ESTATE_AGENT,
        name: RouteNames.PIF_SIGN_ON_BONUS_REAL_ESTATE_AGENT,
        component: () => import('@/views/SignOnBonusRealEstateAgent.vue'),
        meta: {
            layout: 'dashboard',
        },
    },
    {
        path: RoutePaths.PAY_IT_FORWARD_SEND_TIP,
        name: RouteNames.PAY_IT_FORWARD_SEND_TIP,
        component: () => import('@/views/PayItForwardSendTip.vue'),
        meta: {
            layout: 'dashboard',
        },
    },
    {
        path: RoutePaths.POINT_LANDING,
        name: RouteNames.POINT_LANDING,
        component: () => import('@/views/PointLandingPage.vue'),
        props: true,
        meta: {
            layout: 'dashboard',
        },
    },
    {
        // Always goes back to activity, so when used as a deeplink, must
        // be, e.g., my.aven.com/activity/promo/myPromoHere.
        // Otherwise, native mobile app navigation will get screwed up.
        path: RoutePaths.PROMO_SETUP_AUTOPAY_CREDIT,
        name: RouteNames.PROMO_SETUP_AUTOPAY_CREDIT,
        component: () => import('@/views/PromoSetupAutoPayCredit.vue'),
        meta: {
            layout: 'dashboard',
        },
    },
    {
        path: RoutePaths.REINSTATE_CREDIT_LIMIT,
        name: RouteNames.REINSTATE_CREDIT_LIMIT,
        component: () => import('@/views/ReinstateCreditLimit.vue'),
        meta: {
            layout: 'dashboard',
        },
    },
    {
        path: RoutePaths.CLOSE_ACCOUNT,
        name: RouteNames.CLOSE_ACCOUNT,
        component: () => import('@/views/CloseAccountPrompt.vue'),
        meta: {
            layout: 'dashboard',
        },
    },
    {
        path: RoutePaths.CLOSE_ACCOUNT_PERMANENT,
        name: RouteNames.CLOSE_ACCOUNT_PERMANENT,
        component: () => import('@/views/CloseAccountPermanentlyNotice.vue'),
        meta: {
            layout: 'dashboard',
        },
    },
    {
        path: RoutePaths.CLOSE_ACCOUNT_PAYOFF,
        name: RouteNames.CLOSE_ACCOUNT_PAYOFF,
        component: () => import('@/views/PayoffQuote.vue'),
        meta: {
            layout: 'dashboard',
        },
        props: {
            closeAccount: true,
        },
    },
    {
        path: RoutePaths.CLOSE_ACCOUNT_REFINANCE,
        name: RouteNames.CLOSE_ACCOUNT_REFINANCE,
        component: () => import('@/views/CloseAccountRefinance.vue'),
        meta: {
            layout: 'dashboard',
        },
        props: true,
    },
    {
        path: RoutePaths.CLOSE_ACCOUNT_REFINANCE_ESTIMATE,
        name: RouteNames.CLOSE_ACCOUNT_REFINANCE_ESTIMATE,
        component: () => import('@/views/CloseAccountRefinanceEstimate.vue'),
        meta: {
            layout: 'dashboard',
        },
        props: true,
    },
    {
        path: RoutePaths.CLOSE_ACCOUNT_REFINANCE_DOCUSIGN,
        name: RouteNames.CLOSE_ACCOUNT_REFINANCE_DOCUSIGN,
        component: () => import('@/views/CloseAccountRefinanceDocuSign.vue'),
        meta: {
            layout: 'dashboard',
            navigation: RoutePaths.CLOSE_ACCOUNT_REFINANCE_DOCUSIGN,
        },
    },
    {
        path: RoutePaths.PAYOFF_QUOTE,
        name: RouteNames.PAYOFF_QUOTE,
        component: () => import('@/views/PayoffQuote.vue'),
        meta: {
            layout: 'dashboard',
        },
    },
    {
        path: RoutePaths.EMAIL_PAYOFF_QUOTE,
        name: RouteNames.EMAIL_PAYOFF_QUOTE,
        component: () => import('@/views/EmailPayoffQuote.vue'),
        meta: {
            layout: 'dashboard',
        },
        props: true,
    },
    {
        path: RoutePaths.CLOSE_ACCOUNT_WIRE,
        name: RouteNames.CLOSE_ACCOUNT_WIRE,
        component: () => import('@/views/CloseAccountWireTransferDetails.vue'),
        meta: {
            layout: 'dashboard',
        },
    },
    {
        path: RoutePaths.RETENTION_HOME_REATTACH,
        name: RouteNames.RETENTION_HOME_REATTACH,
        component: () => import('@/views/RetentionHomeReAttach.vue'),
        meta: {
            layout: 'dashboard',
        },
    },
    {
        path: RoutePaths.ADD_REVIEW,
        name: RouteNames.ADD_REVIEW,
        component: () => import('@/views/AddReview.vue'),
        meta: {
            layout: 'dashboard',
        },
        props: true,
    },
    {
        path: RoutePaths.SUPPORT_LANDING,
        name: RouteNames.SUPPORT_LANDING,
        component: () => import('@/views/SupportLanding.vue'),
        meta: {
            layout: 'dashboard',
        },
    },
    {
        path: RoutePaths.SUPPORT_ARTICLE,
        name: RouteNames.SUPPORT_ARTICLE,
        component: () => import('@/views/SupportArticle.vue'),
        meta: {
            layout: 'dashboard',
        },
    },
    {
        path: RoutePaths.CALL_BOOKING,
        name: RouteNames.CALL_BOOKING,
        component: () => import('@/views/CallBooking.vue'),
        props: true,
        meta: {
            layout: 'dashboard',
        },
    },
    {
        path: RoutePaths.DQ_INTERSTITIAL,
        name: RouteNames.DQ_INTERSTITIAL,
        component: () => import('@/views/DqInterstitial.vue'),
        meta: {
            layout: 'dashboard',
        },
    },
    {
        path: RoutePaths.GET_BACK_ON_TRACK,
        name: RouteNames.GET_BACK_ON_TRACK,
        component: () => import('@/views/GetBackOnTrack.vue'),
        meta: {
            layout: 'dashboard',
        },
    },
    {
        path: RoutePaths.GET_HELP_PAYMENT_OPTIONS,
        name: RouteNames.GET_HELP_PAYMENT_OPTIONS,
        component: () => import('@/views/GetHelpPaymentOptions.vue'),
        meta: {
            layout: 'dashboard',
        },
    },
    {
        path: RoutePaths.GET_HELP_FEEDBACK,
        name: RouteNames.GET_HELP_FEEDBACK,
        component: () => import('@/views/GetHelpFeedback.vue'),
        meta: {
            layout: 'dashboard',
        },
    },
    {
        path: RoutePaths.AUTOPAY_SURVEY,
        name: RouteNames.AUTOPAY_SURVEY,
        component: () => import('@/views/AutoPaySurvey.vue'),
        meta: {
            layout: 'dashboard',
        },
    },
    {
        path: RoutePaths.AUTOPAY_ENROLLEE_FEEDBACK_SURVEY,
        name: RouteNames.AUTOPAY_ENROLLEE_FEEDBACK_SURVEY,
        component: () => import('@/views/AutoPayEnrolleeFeedbackSurvey.vue'),
        meta: {
            layout: 'dashboard',
        },
    },
    {
        path: RoutePaths.TEST_MODES,
        name: RouteNames.TEST_MODES,
        component: () => import('@/views/TestModes.vue'),
        meta: {
            layout: 'dashboard',
        },
    },
    {
        path: RoutePaths.ACCEPT_HCM_REMEDIATION,
        name: RouteNames.ACCEPT_HCM_REMEDIATION,
        component: () => import('@/views/AcceptHcmRemediation.vue'),
        meta: {
            layout: 'dashboard',
        },
    },
    {
        path: RoutePaths.DECLINE_HCM_REMEDIATION,
        name: RouteNames.DECLINE_HCM_REMEDIATION,
        component: () => import('@/views/DeclineHcmRemediation.vue'),
        meta: {
            layout: 'dashboard',
        },
    },
    {
        path: RoutePaths.SIMPLE_INTEREST_CONVERSION_INTERSTITIAL,
        name: RouteNames.SIMPLE_INTEREST_CONVERSION_INTERSTITIAL,
        component: () => import('@/views/SimpleLoanConversionInterstitial.vue'),
        meta: {
            layout: 'auth',
        },
    },
    {
        path: RoutePaths.LA_WILDFIRE_CO_BT_UNBLOCK_INTERSTITIAL,
        name: RouteNames.LA_WILDFIRE_CO_BT_UNBLOCK_INTERSTITIAL,
        component: () => import('@/views/LaWildfireCoBtUnblockInterstitial.vue'),
        meta: {
            layout: 'auth',
        },
    },
    {
        path: RoutePaths.FIXED_PAYMENT_UPGRADE_OFFER,
        name: RouteNames.FIXED_PAYMENT_UPGRADE_OFFER,
        component: () => import('@/views/FixedMonthlyPaymentUpgrade.vue'),
        meta: {
            layout: 'dashboard',
        },
    },
    {
        path: RoutePaths.ACCEPT_FIXED_PAYMENT_UPGRADE_OFFER,
        name: RouteNames.ACCEPT_FIXED_PAYMENT_UPGRADE_OFFER,
        component: () => import('@/views/AcceptFixedMonthlyPaymentUpgrade.vue'),
        props: true,
        meta: {
            layout: 'auth',
        },
    },
    {
        path: RoutePaths.DECLINE_FIXED_PAYMENT_UPGRADE_OFFER,
        name: RouteNames.DECLINE_FIXED_PAYMENT_UPGRADE_OFFER,
        component: () => import('@/views/DeclineFixedMonthlyPaymentUpgrade.vue'),
        props: true,
        meta: {
            layout: 'auth',
        },
    },
    {
        path: RoutePaths.FIXED_PAYMENT_UPGRADE_INELIGIBLE,
        name: RouteNames.FIXED_PAYMENT_UPGRADE_INELIGIBLE,
        component: () => import('@/views/FixedMonthlyPaymentUpgradeIneligible.vue'),
        meta: {
            layout: 'auth',
        },
    },
    {
        path: RoutePaths.ACCEPT_TERMS_OF_SERVICE,
        name: RouteNames.ACCEPT_TERMS_OF_SERVICE,
        component: () => import('@/views/AcceptTermsOfService.vue'),
        meta: {
            layout: 'dashboard',
        },
    },
    {
        path: RoutePaths.PURCHASING_SURVEY,
        name: RouteNames.PURCHASING_SURVEY,
        component: () => import('@/views/PurchasingSurvey.vue'),
        meta: {
            layout: 'dashboard',
        },
    },
    {
        path: RoutePaths.REQUEST_FULL_CREDIT_LIMIT,
        name: RouteNames.REQUEST_FULL_CREDIT_LIMIT,
        component: () => import('@/views/RequestFullCreditLimit.vue'),
        meta: {
            layout: 'dashboard',
        },
    },
    {
        path: RoutePaths.REFI_SURVEY,
        name: RouteNames.REFI_SURVEY,
        component: () => import('@/views/RefiSurvey.vue'),
        meta: {
            layout: 'dashboard',
        },
    },
    {
        path: RoutePaths.ADC_OVERRIDE,
        name: RouteNames.ADC_OVERRIDE,
        component: () => import('@/views/AdcOverride.vue'),
        meta: {
            layout: 'dashboard',
        },
    },
    {
        path: RoutePaths.ADVANCE_TRANSACTION_NOTIFICATION,
        name: RouteNames.ADVANCE_TRANSACTION_NOTIFICATION,
        component: () => import('@/views/AdvanceTransactionNotification.vue'),
        meta: {
            layout: 'dashboard',
        },
    },
    {
        path: RoutePaths.CREATOR_CHALLENGE,
        name: RouteNames.CREATOR_CHALLENGE,
        component: () => import('@/views/CreatorChallenge.vue'),
        meta: {
            layout: 'dashboard',
        },
    },
    {
        path: RoutePaths.APR_REDUCTION,
        name: RouteNames.APR_REDUCTION,
        component: () => import('@/views/aprReductionOfferPreview.vue'),
        meta: {
            layout: 'dashboard',
        },
    },
    {
        path: RoutePaths.UNIFORM_RESIDENTIAL_LOAN_APPLICATION,
        name: RouteNames.UNIFORM_RESIDENTIAL_LOAN_APPLICATION,
        component: () => import('@/views/uniformResidentialLoanApplication/UniformResidentialLoanApplication.vue'),
        meta: {
            layout: 'dashboard',
        },
    },
    {
        path: RoutePaths.UNIFORM_RESIDENTIAL_LOAN_APPLICATION_MARITAL_STATUS,
        name: RouteNames.UNIFORM_RESIDENTIAL_LOAN_APPLICATION_MARITAL_STATUS,
        component: () => import('@/views/uniformResidentialLoanApplication/MaritalStatus.vue'),
        meta: {
            layout: 'dashboard',
        },
    },
    {
        path: RoutePaths.UNIFORM_RESIDENTIAL_LOAN_APPLICATION_ADDRESS_HISTORY,
        name: RouteNames.UNIFORM_RESIDENTIAL_LOAN_APPLICATION_ADDRESS_HISTORY,
        component: () => import('@/views/uniformResidentialLoanApplication/AddressHistory.vue'),
        meta: {
            layout: 'dashboard',
        },
    },
    {
        path: RoutePaths.UNIFORM_RESIDENTIAL_LOAN_APPLICATION_DEPENDENTS,
        name: RouteNames.UNIFORM_RESIDENTIAL_LOAN_APPLICATION_DEPENDENTS,
        component: () => import('@/views/uniformResidentialLoanApplication/Dependents.vue'),
        meta: {
            layout: 'dashboard',
        },
    },
    {
        path: RoutePaths.UNIFORM_RESIDENTIAL_LOAN_APPLICATION_PROPERTIES,
        name: RouteNames.UNIFORM_RESIDENTIAL_LOAN_APPLICATION_PROPERTIES,
        component: () => import('@/views/uniformResidentialLoanApplication/Properties.vue'),
        meta: {
            layout: 'dashboard',
        },
    },
    {
        path: RoutePaths.UNIFORM_RESIDENTIAL_LOAN_APPLICATION_CURRENT_EMPLOYMENT,
        name: RouteNames.UNIFORM_RESIDENTIAL_LOAN_APPLICATION_CURRENT_EMPLOYMENT,
        component: () => import('@/views/uniformResidentialLoanApplication/CurrentEmployment.vue'),
        meta: {
            layout: 'dashboard',
        },
    },
    {
        path: RoutePaths.UNIFORM_RESIDENTIAL_LOAN_APPLICATION_CURRENT_INCOME,
        name: RouteNames.UNIFORM_RESIDENTIAL_LOAN_APPLICATION_CURRENT_INCOME,
        component: () => import('@/views/uniformResidentialLoanApplication/CurrentIncome.vue'),
        meta: {
            layout: 'dashboard',
        },
    },
    {
        path: RoutePaths.UNIFORM_RESIDENTIAL_LOAN_APPLICATION_PAST_EMPLOYMENT,
        name: RouteNames.UNIFORM_RESIDENTIAL_LOAN_APPLICATION_PAST_EMPLOYMENT,
        component: () => import('@/views/uniformResidentialLoanApplication/PastEmployment.vue'),
        meta: {
            layout: 'dashboard',
        },
    },
    {
        path: RoutePaths.UNIFORM_RESIDENTIAL_LOAN_APPLICATION_OTHER_INCOME,
        name: RouteNames.UNIFORM_RESIDENTIAL_LOAN_APPLICATION_OTHER_INCOME,
        component: () => import('@/views/uniformResidentialLoanApplication/OtherIncome.vue'),
        meta: {
            layout: 'dashboard',
        },
    },
    {
        path: RoutePaths.UNIFORM_RESIDENTIAL_LOAN_APPLICATION_OTHER_ASSETS,
        name: RouteNames.UNIFORM_RESIDENTIAL_LOAN_APPLICATION_OTHER_ASSETS,
        component: () => import('@/views/uniformResidentialLoanApplication/OtherAssets.vue'),
        meta: {
            layout: 'dashboard',
        },
    },
    {
        path: RoutePaths.UNIFORM_RESIDENTIAL_LOAN_APPLICATION_LIABILITIES,
        name: RouteNames.UNIFORM_RESIDENTIAL_LOAN_APPLICATION_LIABILITIES,
        component: () => import('@/views/uniformResidentialLoanApplication/Liabilities.vue'),
        meta: {
            layout: 'dashboard',
        },
    },
    {
        path: RoutePaths.UNIFORM_RESIDENTIAL_LOAN_APPLICATION_DECLARATION_QUESTIONS,
        name: RouteNames.UNIFORM_RESIDENTIAL_LOAN_APPLICATION_DECLARATION_QUESTIONS,
        component: () => import('@/views/uniformResidentialLoanApplication/DeclarationQuestions.vue'),
        meta: {
            layout: 'dashboard',
        },
    },
    {
        path: RoutePaths.UNIFORM_RESIDENTIAL_LOAN_APPLICATION_HMDA,
        name: RouteNames.UNIFORM_RESIDENTIAL_LOAN_APPLICATION_HMDA,
        component: () => import('@/views/uniformResidentialLoanApplication/HMDA.vue'),
        meta: {
            layout: 'dashboard',
        },
    },
    {
        path: RoutePaths.UNIFORM_RESIDENTIAL_LOAN_APPLICATION_CONFIRMATION,
        name: RouteNames.UNIFORM_RESIDENTIAL_LOAN_APPLICATION_CONFIRMATION,
        component: () => import('@/views/uniformResidentialLoanApplication/Confirmation.vue'),
        meta: {
            layout: 'dashboard',
        },
    },
    {
        path: RoutePaths.UNIFORM_RESIDENTIAL_LOAN_APPLICATION_BROKERAGE_AGREEMENT,
        name: RouteNames.UNIFORM_RESIDENTIAL_LOAN_APPLICATION_BROKERAGE_AGREEMENT,
        component: () => import('@/views/uniformResidentialLoanApplication/BrokerageAgreement.vue'),
        meta: {
            layout: 'dashboard',
        },
    },
    {
        path: RoutePaths.UNIFORM_RESIDENTIAL_LOAN_APPLICATION_SUBMITTED,
        name: RouteNames.UNIFORM_RESIDENTIAL_LOAN_APPLICATION_SUBMITTED,
        component: () => import('@/views/uniformResidentialLoanApplication/Submitted.vue'),
        meta: {
            layout: 'dashboard',
            navigation: RoutePaths.UNIFORM_RESIDENTIAL_LOAN_APPLICATION_SUBMITTED,
        },
    },
    {
        path: RoutePaths.MORTGAGE_PAYMENT_REWARDS,
        name: RouteNames.MORTGAGE_PAYMENT_REWARDS,
        component: () => import('@/views/MortgagePaymentRewards.vue'),
        meta: {
            layout: 'dashboard',
        },
    },
    {
        path: RoutePaths.OPEN_MOFO_OFFER,
        name: RouteNames.OPEN_MOFO_OFFER,
        component: () => import('@/views/OpenMOFO.vue'),
        meta: {
            layout: 'dashboard',
        },
    },
    {
        path: RoutePaths.CLOSE_ACCOUNT_MOFO_UPSELL,
        name: RouteNames.CLOSE_ACCOUNT_MOFO_UPSELL,
        component: () => import('@/views/CloseAccountMOFOUpsell.vue'),
        meta: {
            layout: 'dashboard',
        },
    },
    {
        path: RoutePaths.ENGAGEMENT_LINK_BANK_ACCOUNT,
        name: RouteNames.ENGAGEMENT_LINK_BANK_ACCOUNT,
        component: () => import('@/views/EngagementLinkBankAccount.vue'),
        meta: {
            layout: 'dashboard',
        },
    },
    {
        path: RoutePaths.ENGAGEMENT_LINK_BANK_ACCOUNT_SUCCESS,
        name: RouteNames.ENGAGEMENT_LINK_BANK_ACCOUNT_SUCCESS,
        component: () => import('@/views/EngagementLinkBankAccountSuccess.vue'),
        meta: {
            layout: 'dashboard',
        },
        props: true,
    },
    {
        path: RoutePaths.MORTGAGE_CASHBACK_V2_Landing,
        name: RouteNames.MORTGAGE_CASHBACK_V2_Landing,
        component: () => import('@/views/MortgagePaymentRewards.vue'),
        props: { isCashbackV2: true },
        meta: {
            layout: 'dashboard',
        },
    },
    {
        path: RoutePaths.HOME_GUARANTEE,
        name: RouteNames.HOME_GUARANTEE,
        component: () => import('@/views/homeGuarantee/HomeGuarantee.vue'),
        meta: {
            layout: 'dashboard',
        },
    },
    {
        path: RoutePaths.COSTCO_REWARDS,
        name: RouteNames.COSTCO_REWARDS,
        component: () => import('@/views/CostcoRewards.vue'),
        meta: {
            layout: 'dashboard',
        },
    },
    {
        path: RoutePaths.JOB_LOSS_AFFIRMATION,
        name: RouteNames.JOB_LOSS_AFFIRMATION,
        component: () => import('@/views/homeGuarantee/JobLossAffirmation.vue'),
        meta: {
            layout: 'dashboard',
        },
    },
]

const router = new VueRouter({
    mode: 'history',
    base: process.env.BASE_URL,
    routes,
})

// *DO NOT CHANGE* This param is sent in comms by the backend.
// Key is defined in aven_backend/src/manager/communication/communicationManager.ts
export const COMMUNICATION_SHORT_ID_QUERY_KEY = 'cm'

router.beforeEach((routeTo, routeFrom, next) => {
    if (window.zE) {
        // hide zendesk widget if it has been initialized
        window.zE('webWidget', 'hide')
    }

    // attempt to parse for deeplink if conditions are met.
    // comments inside function maybeParseForDeeplink
    const signedIn = useAuthStore().isSignedIn
    const hasSession = useSessionStore().hasSession
    logger.info(`router.beforeEach, from: ${routeFrom.fullPath} to: ${routeTo.fullPath}`)

    const commShortId = routeTo.query[COMMUNICATION_SHORT_ID_QUERY_KEY]
    if (commShortId && typeof commShortId === 'string') {
        const linkToTrack = `${window.location.origin}${routeTo.fullPath}`
        logger.info(`Found commShortId ${commShortId} in routeTo ${routeTo.fullPath}. Storing link ${linkToTrack} in click tracking store`)
        const clickTrackingStore = useClickTrackingStore()
        clickTrackingStore.link = linkToTrack
        // We'll only track the comm click once, so we'll just delete from routeTo.query
        // params so we don't trigger this code path again for the same comm click.
        delete routeTo.query[COMMUNICATION_SHORT_ID_QUERY_KEY]
        if (hasSession) {
            clickTrackingStore.maybeTryTrackClick().then(() => {
                logger.info(`Tracked click on link ${linkToTrack} in router.beforeEach`)
            })
        }
    }

    const result = maybeParseForDeeplink(routeTo, routeFrom)
    if (result?.root) {
        logger.info(`Found root ${result.root} after parsing for deeplink`)
        useGlobalUiStore().setCurrentRootPath(result.root)
    }
    if (result?.deeplink && !signedIn) {
        logger.info(`Found deeplink ${result.deeplink} after parsing for deeplink, but we're not signed in: navigate from ${routeFrom.name} to ${RouteNames.LOGIN}`)
        useGlobalUiStore().updateDeeplinkPath(result.deeplink)
        // mobile app injects device info via query param, we need to propagate query
        // to login.vue
        return next({ name: RouteNames.LOGIN, query: routeTo.query })
    }
    // route name could be empty for both to and from but that doesn't mean they are the same
    if (routeTo.path === routeFrom.path) {
        logger.info(`routeFrom.path: ${routeFrom.path} === routeTo.path ${routeTo.path}, navigation cancelled`)
        return
    }
    // Only allow crypto product customers to go to CryptoRoutes
    if (CryptoRoutePaths.map((route) => route.toString()).includes(routeTo.path) && !useOverviewStore().isCryptoProduct) {
        logger.info(`routeFrom.path: ${routeFrom.path} === routeTo.path ${routeTo.path}, navigation cancelled`)
        if (useDeviceInfoStore().isSingleWebView) {
            logger.info(`single webview, next route is activity, update native tab bar with name: ${RouteNames.ACTIVITY}`)
            sendMessageToNativeApp(NATIVE_MESSAGES.TAB_UPDATE, `tabName=${RouteNames.ACTIVITY}`)
        }
        logger.info(`next route is ${RouteNames.ACTIVITY}`)
        return next({ name: RouteNames.ACTIVITY })
    }

    // Store the previous path to send with analytics events.
    window.previousPath = routeFrom.path

    if (navigationTabRoutePaths.includes(routeTo.path as RoutePaths) && useDeviceInfoStore().isSingleWebView) {
        logger.info(`update native tab bar with name: ${routeTo.name} to: ${routeTo.path}, from: ${routeFrom.path}`)
        sendMessageToNativeApp(NATIVE_MESSAGES.UPDATE_TAB_BAR, `tabName=${routeTo.name}`)
    }

    const isPublic = routeTo.matched.some((record) => record.meta.public)
    //any route that is marked public is allowed with no further checks
    if (isPublic) {
        logger.info(`New route is public: navigate from ${routeFrom.name} to ${routeTo.name}`)
        return next()
    }

    //any route that is allowed with only session jwt
    const allowedWithSessionJwt = routeTo.matched.some((record) => record.meta.allowedWithSessionJwt)
    if (allowedWithSessionJwt && hasSession) {
        logger.info(`New route requires session & we have one: navigate from ${routeFrom.name} to ${routeTo.name}`)
        return next()
    }

    //all other routes require access token which is obtained after successful password login
    if (signedIn) {
        logger.info(`New route requires authenticated user & we have one: navigate from ${routeFrom.name} to ${routeTo.name}`)
        if (routeTo.path === RoutePaths.LOGOUT && !useDeviceInfoStore().isSingleWebView) {
            logger.info(`Routing to ${RoutePaths.LOGOUT} outside of a single web view. Clearing appSessionStorage`)
            appSessionStorage.clear()
        }
        return next()
    }

    logger.info(`New route (${routeTo.name}) requires authenticated user, but we don't have one: navigate from ${routeFrom.name} to ${RouteNames.LOGIN}`)
    return next({ name: RouteNames.LOGIN })
})

const maybeParseForDeeplink = (routeTo: Route, routeFrom: Route): { root: string; deeplink: string } | undefined => {
    if (useAuthStore().isSignedIn) {
        logger.info(`maybeParseForDeeplink() is not parsing for deep link because the user is logged in`)
        return
    }

    const disallowed = DISALLOW_DEEP_LINK_PATHS.find((disallowed) => {
        return routeTo.path.includes(disallowed)
    })
    if (disallowed) {
        logger.info(`${routeTo.path} explicitly disallows deep linking, so maybeParseForDeeplink() is not parsing for deep link`)
        return
    }

    let ignoreDeeplink
    if (useDeviceInfoStore().isSingleWebView) {
        ignoreDeeplink = routeFrom.path !== RoutePaths.SPLASH
        if (ignoreDeeplink) {
            logger.info(`maybeParseForDeeplink() is ignoring any deep links because we're in a native shell and routing from a path that is not ${RoutePaths.SPLASH} (routing from ${routeFrom.path})`)
            return
        }
    } else {
        ignoreDeeplink = routeFrom.path !== RoutePaths.ROOT
        if (ignoreDeeplink) {
            logger.info(`maybeParseForDeeplink() is ignoring any deep links because we're on web and routing from a path that is not ${RoutePaths.ROOT} (routing from ${routeFrom.path})`)
            return
        }
        ignoreDeeplink = [RoutePaths.LOGIN.toString(), RoutePaths.LOGOUT.toString(), RoutePaths.SPLASH.toString()].includes(routeTo.path)
        if (ignoreDeeplink) {
            logger.info(`maybeParseForDeeplink() is ignoring any deep links because we're on web and routing to ${routeTo.path}`)
            return
        }
    }

    logger.info(`maybeParseForDeeplink() is parsing for deep link`)
    const result = getRootAndDeeplinkFromPath(routeTo.path)
    logger.info(`maybeParseForDeeplink() got result parsing for deep link: ${JSON.stringify(result)}`)
    return result
}

export const getRootAndDeeplinkFromPath = (path: string): { root: string; deeplink: string } | undefined => {
    // here on, a url with deeplink launched mSite.
    // my.aven.com/{root}/{deeplink} - compatible with both mobile apps and mSite
    // my.aven.com/{deeplink} - mSite only, will not work with mobile apps
    saveDebugLog(localStorageKey.routerLog, `Getting root and deep link from path: ${path}`)
    const routeToComponents = path.split('/')
    saveDebugLog(localStorageKey.routerLog, `components: ${JSON.stringify(routeToComponents)}`)
    routeToComponents.shift() // first index is always an empty string due to leading / in the routeTo.path
    saveDebugLog(localStorageKey.routerLog, `components shifted: ${JSON.stringify(routeToComponents)}`)
    if (routeToComponents.length === 0) {
        saveDebugLog(localStorageKey.routerLog, `no deeplink in path ${path}`)
        return null
    }

    if (RoutePaths.LOGIN.endsWith(routeToComponents[0])) {
        saveDebugLog(localStorageKey.routerLog, `deeplink ${path}, first component is RouterPaths.LOGIN, discard by shifting by 1`)
        routeToComponents.shift()
        saveDebugLog(localStorageKey.routerLog, `after shifting, components: ${JSON.stringify(routeToComponents)}`)
    }

    if (routeToComponents.length === 0) {
        saveDebugLog(localStorageKey.routerLog, `no deeplink in path ${path}`)
        return null
    }

    // save initial path in vuex store for post login
    // handles /cashOut or /card/cashOut
    // when root (activity, card, statements) is not in the path. By default vuex store initializes currentRootPath to activity
    // root is stored in vuex store currentRootPath.
    // deeplink has to match routes declared above in routes
    let root = RoutePaths.ACTIVITY.toString()
    if (navigationTabRoutePaths.includes(`/${routeToComponents[0]}` as RoutePaths)) {
        root = `/${routeToComponents.shift()}`
        saveDebugLog(localStorageKey.routerLog, `root is ${root}`)
    }

    let deeplink = ''
    routeToComponents.forEach((component) => {
        deeplink += `/${component}`
    })

    // only return result if there is a valid deeplink or root
    // i.e.
    // input: "/card/makePayment" -> root = card, deeplink is /makePayment
    // input "/makePayment" -> root = activity, deeplink is /makePayment
    // input "/card -> root = card, deeplink is ''
    // input "/" this is caught by above statement "if (routeToComponents.length === 0)" and null is returned
    return {
        root,
        deeplink,
    }
}

export default router
