import { defineStore } from 'pinia'
import { openReplay } from '@/utils/openReplay'
import { fetchHomeGuaranteeInfo } from '@/services/api'

export const useHomeGuaranteeStore = () => {
    const homeGuaranteeStore = defineStore('homeGuarantee', {
        state: () => {
            return {
                status: null,
                loading: false,
                lostJobAt: null,
                submitted: false,
                documentAckId: null,
                hasAccountsConnected: false,
            }
        },
        actions: {
            async fetchHomeGuaranteeInfo() {
                this.$patch({
                    loading: true,
                })
                try {
                    const response = await fetchHomeGuaranteeInfo()

                    this.$patch({
                        ...response.data.payload,
                    })
                } finally {
                    this.$patch({
                        loading: false,
                    })
                }
            },
        },
    })()
    openReplay.setUpPiniaStoreTracking(homeGuaranteeStore)
    return homeGuaranteeStore
}
