var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[(_vm.selectionMode === _vm.BalanceTransferInstantOptionsSelectMode.noSelect && _vm.index > 0)?_c('hr'):_vm._e(),_c('div',{staticClass:"custom-control custom-radio custom-control-tight-right h-auto",class:{
            'mb-2': _vm.selectionMode !== _vm.BalanceTransferInstantOptionsSelectMode.noSelect,
        }},[_c('input',{staticClass:"custom-control-input",attrs:{"type":"radio","id":`instantBalanceTransferOption_${_vm.instantBalanceTransferOption.methodfiAccountId}`,"name":`instantBalanceTransferOption_${_vm.instantBalanceTransferOption.methodfiAccountId}`},domProps:{"value":_vm.index},on:{"change":_vm.handleOnSelect}}),_c('label',{staticClass:"d-flex custom-control-label",class:{
                'custom-control-label-tight-right': _vm.shouldShowIcon,
                'custom-control-label-tight-right-no-icon': !_vm.shouldShowIcon,
                'liability-select-option pt-2 pb-2': _vm.selectionMode !== _vm.BalanceTransferInstantOptionsSelectMode.noSelect,
            },attrs:{"for":`instantBalanceTransferOption_${_vm.instantBalanceTransferOption.methodfiAccountId}`,"data-testid":`instant-balance-transfer-option-${_vm.index}`}},[_c('div',{staticClass:"d-flex align-items-center ms-2 me-2"},[_c('img',{staticClass:"financial-institution-logo",attrs:{"src":_vm.instantBalanceTransferOption.logoUrl,"alt":_vm.instantBalanceTransferOption.financialInstitutionName}})]),_c('div',{staticClass:"d-flex min-width-0 tw-items-center"},[_c('div',{staticClass:"text-start min-width-0"},[_c('p',{staticClass:"fw-bold mb-0 text-truncate"},[_vm._v(" "+_vm._s(_vm.instantBalanceTransferOption.name)+" "+_vm._s(_vm.instantBalanceTransferOption.mask && _vm.instantBalanceTransferOption.mask !== '0000' ? `(${_vm.instantBalanceTransferOption.mask})` : '')+" ")]),(_vm.instantBalanceTransferOption.isRecurringBalanceTransferActive)?_c('div',{staticClass:"mb-0 small text-muted"},[_vm._v(" "+_vm._s(_vm.$t('components.balanceTransfer.instantSelect.nextTransferOn', { transferDate: _vm.dateOfNextTransfer }))+" ")]):(_vm.isEnrolledInMethodfi)?_c('div',[(_vm.instantBalanceTransferOption.balanceCents !== null)?_c('p',{staticClass:"mb-0"},[_vm._v(" "+_vm._s(_vm.toFormattedUSD(_vm.instantBalanceTransferOption.balanceCents / 100.0))+" ")]):_vm._e(),_c('p',{staticClass:"mb-0 small text-muted"},[_vm._v(" "+_vm._s(_vm.instantBalanceTransferOption.lastUpdateRelativeTimeStr)+" ")])]):_vm._e()])])])])])
}
var staticRenderFns = []

export { render, staticRenderFns }