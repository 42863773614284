import { logger } from '@/utils/logger'
import { v4 as uuid } from 'uuid'

// Keep in sync with LocationPermissionsStatus in aven_android HomeWebViewFragment.ts
export enum LocationPermissionsStatus {
    AuthorizedWhenInUse = 'authorizedWhenInUse',
    AuthorizedAlways = 'authorizedAlways',
    // It's implied that any form of coarse-only location permissions are treated the same by us
    CoarseOnly = 'coarseOnly',
    Denied = 'denied',
    Unknown = 'unknown',
    NotDetermined = 'notDetermined',
    Restricted = 'restricted',
}

const NATIVE_RETURN_EVENT_NAME = 'nativeReturn'

// Always keep these in sync with aven_android and aven_ios projects
export enum NativeFunction {
    testing = 'testing',
    throws = 'throws',
    getVersion = 'getVersion',
    getCurrentLocationPermissions = 'getCurrentLocationPermissions',
    getCurrentContactsPermissions = 'getCurrentContactsPermissions',
    getContactsPermissions = 'getContactsPermissions',

    getCurrentCameraPermissions = 'getCurrentCameraPermissions',
    getCameraPermissions = 'getCameraPermissions',

    getCurrentMicrophonePermissions = 'getCurrentMicrophonePermissions',
    getMicrophonePermissions = 'getMicrophonePermissions',

    getCurrentStoragePermissions = 'getCurrentStoragePermissions',
    getStoragePermissions = 'getStoragePermissions',

    getCurrentPushNotificationPermissions = 'getCurrentPushNotificationPermissions',
    requestPushNotificationPermissions = 'requestPushNotificationPermissions',

    getBackgroundLocationPermissions = 'getBackgroundLocationPermissions',
    getForegroundLocationPermissions = 'getForegroundLocationPermissions',
    getContactsJson = 'getContactsJson',
    takeVideo = 'takeVideo',
    getLatestLocationJson = 'getLatestLocationJson',
    openVideoPickerAndUploadCreatorChallenge = 'openVideoPickerAndUploadCreatorChallenge',

    setHasAskedForAppStoreReview = 'setHasAskedForAppStoreReview',
    askForAppStoreReview = 'askForAppStoreReview',

    openAscendaDeepLink = 'openAscendaDeepLink',
}

export enum NativeAsyncFunction {
    share = 'share',
}

// Keep in sync with PermissionStatus in aven_android HomeWebViewFragment.ts
export enum PermissionStatus {
    Authorized = 'authorized',
    Denied = 'denied',
    Unknown = 'unknown',
    NotDetermined = 'notDetermined',
    Restricted = 'restricted',
}

// All of these are async b/c in iOS webkit.messageHandlers always return promises
// In android they are synchronous, but awaiting them won't harm anything
// If you attempt to call a handler that doesn't exist, the await call never returns
class JavascriptNativeInterop {
    public async testing(): Promise<string | void> {
        return this.invoke<string>(NativeFunction.testing)
    }

    public async throws(): Promise<string | void> {
        return await this.invoke<string>(NativeFunction.throws)
    }

    public async getVersion(): Promise<string | void> {
        return await this.invoke<string>(NativeFunction.getVersion)
    }

    public async getCurrentLocationPermissions(): Promise<LocationPermissionsStatus> {
        return (await this.invoke<LocationPermissionsStatus>(NativeFunction.getCurrentLocationPermissions)) || LocationPermissionsStatus.Unknown
    }

    public async getCurrentContactsPermissions(): Promise<PermissionStatus> {
        return (await this.invoke<PermissionStatus>(NativeFunction.getCurrentContactsPermissions)) || PermissionStatus.Unknown
    }

    public async getContactsPermissions(): Promise<void> {
        return await this.invoke<void>(NativeFunction.getContactsPermissions)
    }

    public async getCurrentCameraPermissions(): Promise<PermissionStatus> {
        return (await this.invoke<PermissionStatus>(NativeFunction.getCurrentCameraPermissions)) || PermissionStatus.Unknown
    }

    public async getCameraPermissions(): Promise<void> {
        return await this.invoke<void>(NativeFunction.getCameraPermissions)
    }

    public async getCurrentMicrophonePermissions(): Promise<PermissionStatus> {
        return (await this.invoke<PermissionStatus>(NativeFunction.getCurrentMicrophonePermissions)) || PermissionStatus.Unknown
    }

    public async getMicrophonePermissions(): Promise<void> {
        return await this.invoke<void>(NativeFunction.getMicrophonePermissions)
    }

    public async getCurrentStoragePermissions(): Promise<PermissionStatus> {
        return (await this.invoke<PermissionStatus>(NativeFunction.getCurrentStoragePermissions)) || PermissionStatus.Unknown
    }

    public async getStoragePermissions(): Promise<void> {
        return await this.invoke<void>(NativeFunction.getStoragePermissions)
    }

    public async getCurrentPushNotificationPermissions(): Promise<PermissionStatus> {
        return (await this.invoke<PermissionStatus>(NativeFunction.getCurrentPushNotificationPermissions)) || PermissionStatus.Unknown
    }

    public async requestPushNotificationPermissions(): Promise<void> {
        return await this.invoke<void>(NativeFunction.requestPushNotificationPermissions)
    }

    public async getBackgroundLocationPermissions(): Promise<void> {
        return await this.invoke<void>(NativeFunction.getBackgroundLocationPermissions)
    }

    public async getForegroundLocationPermissions(): Promise<void> {
        return await this.invoke<void>(NativeFunction.getForegroundLocationPermissions)
    }

    public async getContactsJson(): Promise<string | void> {
        return await this.invoke<string>(NativeFunction.getContactsJson)
    }

    public async takeAndReturnB64Video(): Promise<string | void> {
        return await this.invoke<string>(NativeFunction.takeVideo)
    }

    public async getLatestLocationJson(): Promise<string | void> {
        return await this.invoke<string>(NativeFunction.getLatestLocationJson)
    }

    public async openVideoPickerAndUploadCreatorChallenge(): Promise<string | void> {
        return await this.invoke<string>(NativeFunction.openVideoPickerAndUploadCreatorChallenge)
    }

    public async setHasAskedForAppStoreReview(): Promise<string | void> {
        return await this.invoke<string>(NativeFunction.setHasAskedForAppStoreReview)
    }

    public async askForAppStoreReview(): Promise<string | void> {
        return await this.invoke<string>(NativeFunction.askForAppStoreReview)
    }

    public async openAscendaDeepLink(loginLink: string, deepLink: string): Promise<void> {
        await this.invoke<string>(NativeFunction.openAscendaDeepLink, { loginLink, deepLink })
    }

    // Returns boolean if a given NativeFunction is supported on the device
    public isSupported(func: NativeFunction): boolean {
        return !!window.native?.[func]
    }

    // This func may always fail and return void if the underlying app does not support the call
    private async invoke<T>(func: NativeFunction, argObject?: object): Promise<T | void> {
        // You always want to check if the underlying func exists before you call it
        // Otherwise (for reasons not yet determined) the async call below may never return (and cause a complete freeze)
        if (!this.isSupported(func)) {
            logger.log(`Refusing to invoke native.${func}() because it does not exist`)
            return
        }

        const stringifiedArgs = argObject ? JSON.stringify(argObject) : ''
        // No matter what "T" is in this func, the native value will always be "string | undefined"
        let retVal: string | undefined
        if (stringifiedArgs) {
            logger.log(`Invoking native.${func}(${stringifiedArgs})`)
            retVal = (await window.native[func](stringifiedArgs)) as string | undefined
        } else {
            logger.log(`Invoking native.${func}()`)
            retVal = (await window.native[func]()) as string | undefined
        }

        // Don't dump large b64 payloads. If retVal is < 1024 in length or not a string, just print retVal
        const loggedRetVal = retVal?.length > 1024 ? retVal.substring(0, 1024) + '...' : retVal

        logger.log(`Return value from native.${func}(): ${loggedRetVal}`)
        // We're using a force cast here because we rely on the callers to ensure that T is convertable from str
        // i.e. T might be an enumerable and retVal might be an enumerable key
        return retVal as unknown as T | void
    }

    public async share(message: string) {
        return await this.invokeAsync<{
            completed: boolean
            selectedApp: string | null
        }>(NativeAsyncFunction.share, { message })
    }

    // Returns boolean if a given NativeAsyncFunction is supported on the device
    public isAsyncSupported(func: NativeAsyncFunction): boolean {
        return !!window.nativeAsync?.[func]
    }

    public nativeReturn = (data: string) => {
        const nativeReturnEvent = new CustomEvent(NATIVE_RETURN_EVENT_NAME, {
            detail: JSON.parse(data),
        })

        document.dispatchEvent(nativeReturnEvent)
    }

    /**
     * This method allows you to execute native-side code from JS (just as `invoke` does), the only difference is that
     * this one is meant to be used for async operations (operations where you can't get the result immediately, and you
     * have to wait for asynchronous code to conclude in the native side).
     * If you want to add a new method to this class that needs this `invokeAsync`, you will have to make sure that the
     * corresponding native function gets added to window.nativeAsync and that it calls window.javascriptNativeInterop.nativeReturn(jsonResponse)
     * through JS evaluation once the async operation has concluded in order to provide the JS side the result.
     * @param {NativeAsyncFunction} func - The native function identifier, registered in NativeAsyncFunction enum.
     * @param {object} [argObject] - Optional plain object that will be passed to the native function and received as a JSON.
     * It can't be called directly, another public method from this class should exist, and it should call it.
     * @private
     */
    private async invokeAsync<T>(func: NativeAsyncFunction, argObject?: object): Promise<T | void> {
        if (!this.isAsyncSupported(func)) {
            logger.log(`Refusing to invoke nativeAsync.${func}() because it does not exist`)
            return
        }

        return new Promise((resolve, reject) => {
            const id = uuid()

            const handler = (
                e: CustomEvent<{
                    id: string
                    value: T
                    error: string
                }>
            ) => {
                const { id: incomingId, value, error } = e.detail
                if (incomingId !== id) return

                document.removeEventListener(NATIVE_RETURN_EVENT_NAME, handler)

                if (error) {
                    reject(new Error(error))

                    return
                }

                resolve(value)
            }

            document.addEventListener(NATIVE_RETURN_EVENT_NAME, handler)

            const args = {
                id,
                argument: argObject,
            }

            const stringifiedArgs = JSON.stringify(args)

            logger.log(`Invoking nativeAsync.${func}(${stringifiedArgs})`)
            window.nativeAsync[func](stringifiedArgs)
        })
    }
}

export const javascriptNativeInterop = new JavascriptNativeInterop()
window.javascriptNativeInterop = javascriptNativeInterop
