import { CustomerInfo } from './customerInfoStore.types'
import { defineStore } from 'pinia'
import { getConsents } from '@/services/api'
import { openReplay } from '@/utils/openReplay'
import { AvenCardProductType } from 'aven_types'
import { assertNever } from '@/utils/assertUtil'

export const productName = (productType: AvenCardProductType) => {
    switch (productType) {
        case AvenCardProductType._NONE:
            throw new Error(`Currently selected product type is ${AvenCardProductType._NONE}`)
        case AvenCardProductType.CRYPTO:
            return 'Crypto Card'
        case AvenCardProductType.AUTO:
        case AvenCardProductType.AUTO_REVOLVING_FIRST:
            return 'Auto Card'
        case AvenCardProductType.HOME:
        case AvenCardProductType.HOME_NO_LOYALTY:
        case AvenCardProductType.HOME_LIMITED_MODE:
        case AvenCardProductType.HOME_SECURED_PLUS:
            return 'Home Card'
        case AvenCardProductType.ASSET_ATTESTATION:
            return 'Asset Card'
        default:
            throw assertNever(productType, 'Unknown productType')
    }
}

export const useCustomerInfoStore = () => {
    const customerInfoStore = defineStore('customerInfo', {
        state: (): CustomerInfo => {
            return {
                firstName: '',
                lastName: '',
                email: '',
                phoneNumber: '',
                addressLine1: '',
                addressLine2: '',
                city: '',
                state: '',
                postalCode: '',
                country: '',
                existingConsents: undefined,
                customerTimeZone: undefined,
                productInfoDetails: undefined,
                currentlySelectedProductInfo: undefined,
            }
        },
        getters: {
            hasMultipleProducts: (state) => {
                return state.productInfoDetails && state.productInfoDetails.length > 1
            },
            currentlySelectedProductName: (state) => {
                if (!state.currentlySelectedProductInfo) {
                    return ''
                }
                if (state.currentlySelectedProductInfo.nameForActivityScreen) {
                    return state.currentlySelectedProductInfo.nameForActivityScreen
                }
                // Todo The below can be removed once merged to master because nameForActivityScreen
                //  will always be available.
                return productName(state.currentlySelectedProductInfo.productType)
            },
            fullName: (state) => `${state.firstName} ${state.lastName}`,
        },
        actions: {
            async refreshConsentsIfNecessary(forceRefresh: boolean) {
                if (forceRefresh || !this.existingConsents) {
                    const resp = await getConsents()
                    this.existingConsents = resp.data.payload.consents
                }
            },
        },
    })()
    openReplay.setUpPiniaStoreTracking(customerInfoStore)
    return customerInfoStore
}
