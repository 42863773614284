<script setup lang="ts">
    import ProductSelectionHeader from '@/components/ProductSelectionHeader.vue'
    import LoadingIndicator from '@/components/LoadingIndicator.vue'
    import { ref, defineProps } from 'vue'
    import { ProductInfoDetails } from 'aven_types'
    import { logger } from '@/utils/logger'
    import { toFormattedUSD } from '@/mixins/format'
    import { TranslateResult } from 'vue-i18n'
    import dayjs from 'dayjs'
    import { i18n } from '@/utils/i18n'
    import { UTC_TIMEZONE } from '@/data/constants'
    import { ProductInfoResponse } from '@/services/avenAppApi'
    import isNil from 'lodash/isNil'
    import { productName, useCustomerInfoStore } from '@/store/customerInfoStore'

    const { productInfoResponse, onSubmit } =
        defineProps<{
            productInfoResponse: ProductInfoResponse
            onSubmit: (productInfoDetails: ProductInfoDetails) => Promise<void>
        }>()

    const loading = ref(false)
    const errorText = ref('')

    const currentDate = new Date()

    const title = (() => {
        const hours = currentDate.getHours()
        // between midnight and noon say 'Good Morning', between noon and 5pm say 'Good Afternoon', otherwise say 'Good Evening'
        if (hours < 12) {
            return i18n.t('pages.productSelection.greeting.morning')
        } else if (hours < 17) {
            return i18n.t('pages.productSelection.greeting.afternoon')
        } else {
            return i18n.t('pages.productSelection.greeting.evening')
        }
    })()

    const formattedDate = currentDate.toLocaleDateString('en-US', {
        year: 'numeric',
        month: 'long',
        day: 'numeric',
        timeZone: productInfoResponse.customerTimeZone,
    })

    const cardTitle = (productSelectionDetails: ProductInfoDetails): string => {
        if (productSelectionDetails.nameForProductSelectionScreen) {
            return productSelectionDetails.nameForProductSelectionScreen
        }
        // Todo The below can be removed once merged to master because nameForProductSelectionScreen
        //  will always be available.
        const name = productName(productSelectionDetails.productType)
        return `${name.toUpperCase()} (••••${productSelectionDetails.last4RedactedAccessDeviceNumber})`
    }

    const handleProductSelection = async (productInfoDetails: ProductInfoDetails) => {
        loading.value = true
        errorText.value = ''
        try {
            await onSubmit(productInfoDetails)
            useCustomerInfoStore().currentlySelectedProductInfo = productInfoDetails
        } catch (error) {
            logger.fatal(`Error changing product to ${JSON.stringify(productInfoDetails)}`, error)
            errorText.value = i18n.t('pages.productSelection.errorText')
        } finally {
            loading.value = false
        }
    }

    const shouldShowCurrentBalance = (productInfoDetail: ProductInfoDetails) => {
        if (isNil(productInfoDetail.currentBalance)) {
            logger.fatal(`Product selection screen should be able to show current balance, but did not receive it for ${JSON.stringify(productInfoDetail)}`)
            return false
        }
        return true
    }

    const upcomingDueDate = (productInfoDetail: ProductInfoDetails): TranslateResult => {
        if (!productInfoDetail.upcomingDueDate) {
            return ''
        }
        // Payment due dates come from avenStatement.paymentDueDate, which are in UTC time & always midnight Eastern
        // (e.g. 2025-01-01 05:00:00). However only the date part is valid for due date purposes, not the time part.
        // That's fine since we're only displaying the date, but we must interpret the due date in UTC time so we
        // extract the correct due date.
        const formatted = dayjs(productInfoDetail.upcomingDueDate).tz(UTC_TIMEZONE).format('MMM D, YYYY')
        return i18n.t('pages.productSelection.upcomingDueDate', { formattedUpcomingDueDate: formatted })
    }
</script>

<template>
    <div
        v-if="loading"
        class="container"
    >
        <loading-indicator
            v-if="loading"
            :title="$t('global.loadingMessage.loading').toString()"
        />
    </div>
    <div v-else>
        <product-selection-header
            :title="title"
            :subtitle="formattedDate"
            class="mobile-header"
            data-testid="product-select-header"
        />
        <div>
            <div
                v-if="errorText"
                class="alert alert-warning text-center mt-1"
                role="alert"
            >
                {{ errorText }}
            </div>
            <div
                v-for="productInfoDetail in productInfoResponse.productInfoDetails"
                :key="productInfoDetail.productType"
                class="mt-2"
            >
                <div
                    class="custom-control custom-radio custom-control-tight-right h-auto"
                    :data-testid="`product-select-detail-${productInfoDetail.productType}`"
                >
                    <input
                        type="radio"
                        :id="`productSelectionOption_${productInfoDetail.productType}_${productInfoDetail.last4RedactedAccessDeviceNumber}`"
                        :name="`productSelectionOption_${productInfoDetail.productType}_${productInfoDetail.last4RedactedAccessDeviceNumber}`"
                        class="custom-control-input"
                        @change="handleProductSelection(productInfoDetail)"
                    >
                    <label
                        class="product-selection-option d-flex custom-control-label custom-control-label-tight-right p-2 bg-white"
                        :for="`productSelectionOption_${productInfoDetail.productType}_${productInfoDetail.last4RedactedAccessDeviceNumber}`"
                    >
                        <div class="d-flex">
                            <div>
                                <p class="section-header mb-0">
                                    {{ cardTitle(productInfoDetail) }}
                                </p>
                                <h3
                                    v-if="shouldShowCurrentBalance(productInfoDetail)"
                                    class="fw-bold mb-0"
                                    data-testid="formatted-current-balance"
                                >
                                    {{ toFormattedUSD(productInfoDetail.currentBalance) }}
                                </h3>
                                <p
                                    v-if="shouldShowCurrentBalance(productInfoDetail)"
                                    class="small mb-0"
                                >Current Balance</p>
                                <p
                                    v-if="productInfoDetail.upcomingDueDate"
                                    class="small text-muted mb-0"
                                    data-testid="formatted-upcoming-due-date"
                                >
                                    {{ upcomingDueDate(productInfoDetail) }}
                                </p>
                            </div>
                        </div>
                    </label>
                </div>
            </div>
        </div>
    </div>
</template>

<style lang="scss" scoped>
    .custom-control-label-tight-right::before {
        top: calc(4.5rem / 2) !important;
        right: 1.5rem !important;
        width: 48px !important;
        height: 48px !important;
        background-image: url('../assets/right-arrow-round.svg');
        background-size: contain;
        background-position: center;
        background-repeat: no-repeat;
        border: none;
    }

    .custom-control-label-tight-right::after {
        top: calc((85px - 48px) / 2);
        right: 1rem;
    }

    .custom-control-label-tight-right {
        padding-right: 80px;
    }

    .product-selection-option {
        border: 1px solid $gray-200;
        border-radius: 8px;
        cursor: pointer;
    }

    .product-selection-option:hover {
        box-shadow: $shadow-shallow;
    }
</style>
