import { defineStore } from 'pinia'
import { openReplay } from '@/utils/openReplay'
import { AuthInfo } from '@/store/authStore.types'
import { switchProducts } from '@/services/avenAppApi'
import { logger } from '@/utils/logger'
import { ProductInfoDetails } from 'aven_types'
import { useSessionStorage } from '@vueuse/core'
import { useCustomerInfoStore } from '@/store/customerInfoStore'
import { resetAllProductSpecificStoresRequiringAuthentication } from '@/store/storeUtils'

export const useAuthStore = () => {
    const authStore = defineStore('auth', {
        state: (): AuthInfo => {
            return {
                jwtToken: useSessionStorage<string | null>('jwtToken', null),
            }
        },
        getters: {
            isSignedIn: (state): boolean => {
                return !!state.jwtToken
            },
        },
        actions: {
            async trySwitchProducts(newProduct: ProductInfoDetails): Promise<{ success: boolean }> {
                try {
                    logger.info(`Trying to switch products to ${JSON.stringify(newProduct)}`)
                    const response = await switchProducts(newProduct.productType, newProduct.last4RedactedAccessDeviceNumber)
                    if (response.data.success) {
                        logger.info(`Successfully switched products to ${JSON.stringify(newProduct)}`)
                        resetAllProductSpecificStoresRequiringAuthentication()
                        this.jwtToken = response.data.payload.jwt.accessJWT
                        useCustomerInfoStore().currentlySelectedProductInfo = newProduct
                        return { success: true }
                    } else {
                        logger.info(`Unsuccessful switching products to ${JSON.stringify(newProduct)}: ${JSON.stringify(response.data)}`)
                        return { success: false }
                    }
                } catch (e) {
                    logger.fatal(`Error switching products to ${JSON.stringify(newProduct)}`, e)
                    return { success: false }
                }
            },
        },
    })()
    openReplay.setUpPiniaStoreTracking(authStore)
    return authStore
}
