<template>
    <header>
        <div
            class="container"
            data-testid="app-header-title"
        >
            <h3
                class="heading-3 mb-0 d-flex flex-row align-items-center"
                @click="onTitleBackClickHandler"
                @keydown="onTitleBackClickHandler"
                data-testid="app-header-title-text"
            >
                <img
                    v-if="!!onTitleBackClick"
                    class="left-arrow"
                    src="@/assets/left-arrow.svg"
                    alt="Title Back Icon"
                >
                {{ title }}
            </h3>
            <div class="d-flex align-items-center">
                <div
                    v-if="shouldShowPayItForward() && rewardType === 'classicPif'"
                    @click="() => goToPayItForward(GoToPayItForwardCaller.PifPill)"
                    @keydown.enter="() => goToPayItForward(GoToPayItForwardCaller.PifPill)"
                    class="pay-it-forward-btn text-btn-shared"
                >
                    Share {{ payItForwardAmount }}
                </div>
                <div
                    @click="showSupportPage"
                    @keydown.enter="showSupportPage"
                    class="help-text-btn text-btn-shared"
                    data-testid="button-help-text-header"
                >
                    Help
                </div>
            </div>
        </div>
    </header>
</template>

<script>
    import zendeskMixin from '@/mixins/zendeskMixin'
    import goToPayItForward, { GoToPayItForwardCaller } from '@/mixins/goToPayItForward'
    import format from '@/mixins/format'
    import payItForwardMixin from '@/mixins/payItForwardMixin'
    import { PifScreen } from '@/utils/pifManager'
    import { RouteNames } from '@/routes/router.types'

    const pathToLabel = [
        {
            name: RouteNames.STATEMENTS,
            label: 'statements',
        },
        {
            name: RouteNames.REWARDS,
            label: 'rewards',
        },
        {
            name: RouteNames.CARD,
            label: 'activity',
        },
        {
            name: RouteNames.ACTIVITY,
            label: 'activity',
        },
    ]

    export default {
        name: 'AppHeader',
        mixins: [zendeskMixin, goToPayItForward, format, payItForwardMixin],
        props: {
            title: {
                type: String,
                required: true,
            },
            onTitleBackClick: {
                type: Function,
                required: false,
            },
        },
        async mounted() {
            await this.getPayItForwardData(PifScreen.HEADER)
        },
        data() {
            return {
                showingSupport: true,
            }
        },
        computed: {
            GoToPayItForwardCaller() {
                return GoToPayItForwardCaller
            },
            label: function () {
                const obj = pathToLabel.find((o) => o.name === this.$route.name)
                return obj ? obj.label : ''
            },
            payItForwardAmount: function () {
                return this.toFormattedUSDNoCents(this.payItForwardMaxAmount)
            },
        },
        methods: {
            showSupportPage: async function () {
                this.$logEvent('click_button_aven_my_help')
                return await this.$router.push({ path: '/support', query: { label: this.label } })
            },
            onTitleBackClickHandler: function () {
                if (this.onTitleBackClick) {
                    this.onTitleBackClick()
                }
            },
        },
    }
</script>

<style lang="scss" scoped>
    @import 'node_modules/aven_shared/src/styles/components/appHeader';
</style>
