<template>
    <div v-if="shouldShowPayItForward()">
        <div
            class="card"
            v-if="rewardType === 'travelRewardsV0'"
        >
            <img
                class="w-auto"
                src="@/assets/images/pif/las-vegas.webp"
                alt="Las Vegas Sign"
            >
        </div>
        <div
            class="card"
            v-if="rewardType === 'travelTicketsBahamas'"
        >
            <img
                class="w-auto"
                src="@/assets/images/pif/bahamas.webp"
                alt="Beach"
            >
        </div>
        <card-block v-if="showPifInfoCard">
            <div class="d-flex flex-column">
                <div class="d-flex">
                    <div class="d-flex flex-column flex-grow-1 align-content-end">
                        <h6 class="fw-bold mb-2">
                            {{ $t(`pages.payItForward.${rewardType}.altTitle`, { maxAmount: toFormattedUSDNoCents(this.payItForwardMaxAmount) }) }}
                        </h6>
                        <p class="mt-0 mb-0">
                            {{ $t(`pages.payItForward.${rewardType}.descriptionCard`, { maxAmount: toFormattedUSDNoCents(this.payItForwardMaxAmount) }) }}
                            <a
                                @keydown.enter="openInfoDialog"
                                @click="openInfoDialog"
                                class="info-link"
                            >
                                <img
                                    width="16px"
                                    height="16px"
                                    src="@/assets/info-icon-outline.svg"
                                    alt="Info Button"
                                >
                            </a>
                        </p>
                    </div>
                    <div class="flex-grow-0 align-self-center">
                        <base-button
                            class="pif-continue-btn ms-2"
                            data-testid="pif-card-arrow-btn"
                            @click="() => goToPayItForward(GoToPayItForwardCaller.PifCardArrow, this.screenName)"
                        >
                            <lottie-player
                                ref="player"
                                :autoplay="true"
                                :src="animation"
                                :loop="true"
                                :intermission="3000"
                                :speed="1"
                                mode="normal"
                                :style="{ height: '100%', background: '#00000000' }"
                            />
                        </base-button>
                    </div>
                </div>
            </div>
        </card-block>
    </div>
</template>

<script>
    import '@lottiefiles/lottie-player'
    import BaseButton from '@/components/base/BaseButton'
    import CardBlock from '@/components/CardBlock'
    import goToPayItForward, { GoToPayItForwardCaller } from '@/mixins/goToPayItForward'
    import payItForwardMixin, { shouldShowPayItForward } from '@/mixins/payItForwardMixin'
    import lottieArrowAnimation from '@/assets/animations/lottie/continue-arrow-loop.json'
    import generic from '@/utils/generic'

    export default {
        name: 'PayItForwardCard',
        components: { CardBlock, BaseButton },
        mixins: [payItForwardMixin, goToPayItForward],
        props: {
            screenName: {
                type: String,
                default: 'pif_card_view',
            },
        },
        async mounted() {
            this.$logEvent('pif_card_view')
            await this.getPayItForwardData(this.screenName)
        },
        computed: {
            GoToPayItForwardCaller() {
                return GoToPayItForwardCaller
            },
            showPifInfoCard() {
                return this.payItForwardMaxAmount > 0
            },
        },
        methods: {
            shouldShowPayItForward,
            formatAmount(amount) {
                return generic.prettyPrintDollarAmount(amount)
            },
            openInfoDialog() {
                this.$emit('open-pif-info-dialog')
            },
        },
        data() {
            return {
                animation: JSON.stringify(lottieArrowAnimation),
            }
        },
    }
</script>

<style>
    .pif-continue-btn {
        height: 64px;
        width: 64px;
    }

    .pif-continue-btn button {
        padding: 5px;
    }

    .info-link {
        display: inline-flex;
    }
</style>
